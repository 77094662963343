@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@300;400;500;600;700;800&display=swap");

html {
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: auto;
}

body {
  margin: 0px;
  padding: 0px;
  background-image: url(../images/banner.png);
  background-size: cover;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: #121212;
  font-size: 15px;
  font-family: "League Spartan", sans-serif !important;
  overflow-x: hidden;
  position: relative;
  min-height: 100%;
  width: 100%;
  padding-bottom: 77px;
}

.t-green {
  color: #22b15b;
}

.t-red {
  color: #f93838;
}

hr {
  border-top: 1px dashed #5a61723b;
  background: no-repeat;
  opacity: 1;
}

a {
  color: #ff00c4;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

.t-blue {
  color: #212121;
  font-weight: 500;
}
.t-pink {
  color: #ff00c4;
}

.t-white {
  color: #121212;
}

p {
  font-size: 17px;
  line-height: 26px;
  font-weight: 400;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.mb-0 {
  margin-bottom: 0px;
}

.t-grey,
.t-gray {
  color: #b1b1b1;
}

.headbg {
  background: none;
  border-radius: 0px;
  padding: 10px 0px;
  position: relative;
  z-index: 999;
  width: 100%;
  left: 0px;
}

.headermenu {
  position: relative;
  width: 100%;
}

.headbg .navbar-nav li a.nav-link {
  color: #121212;
  padding: 12px 20px;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 18px;
}

.headbg .navbar-nav li {
  padding: 0px 4px;
}

.headbg .dropdown-bs-toggle i {
  margin-left: 10px;
}

.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #121212;
}

.headbg .navbar-nav li.nav-item a.btn:hover {
  color: #121212 !important;
}

.headbg .navbar-nav li a.nav-link:hover,
.headbg .navbar-nav li a.nav-link:focus,
.headbg .navbar-nav li a.nav-link:active,
.headbg .navbar-nav li.active a,
.headbg .dropdown.active .dropdown-toggle {
  color: #ff00c7 !important;
}

.userdropimg img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid #ccc;
  background: #fff;
}

.headbg .dropdown-menu {
  background: #fff;
  padding: 0px;
  border-radius: 0px;
  margin-top: 21px;
  left: 0px;
  right: inherit;
  min-width: 188px;
  border: 0px;
  box-shadow: 0px 0px 13px #0000001f;
}

.headbg .dropdown-menu a {
  color: #121212;
  font-size: 15px;
  padding: 13px 15px;
  text-transform: capitalize;
  font-weight: 500;
}

.headbg .dropdown-menu a:last-child {
  border-bottom: 0px;
}

.headbg .dropdown-menu a:hover,
.headbg .dropdown-menu a:focus,
.headbg .dropdown-menu a:active {
  background: #ffff;
  color: #121212;
}

.usermenu .dropdown-toggle {
  padding-right: 0px !important;
}

.usermenu .dropdown-toggle::after {
  display: none;
}

.headbg button:focus {
  outline: none;
  box-shadow: none;
}

.logo {
  width: 100%;
  max-width: 133px;
  margin-top: 0px;
}

.heading-box {
  padding: 17px 16px;
  margin: 0px;
  background: none;
  color: #120d12;
  font-size: 25px;
  border-bottom: 1px solid #e6e6e6;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  line-height: 21px;
  font-family: "League Spartan", sans-serif !important;
}

.panelcontentbox {
  background: #fff;
  border-radius: 10px;
  position: relative;
  z-index: 9;
}

.contentbox {
  padding: 35px 35px;
}

.tabrightbox {
  position: absolute;
  top: 17px;
  right: 20px;
}

.sitetable thead tr th {
  border: 0px;
  border-bottom: 1px solid #e6e6e6 !important;
  color: #121212;
  font-size: 18px;
  padding: 11px 14px;
  font-weight: 600;
  border: 0px;
  background: none;
  white-space: nowrap;
  position: -ms-sticky;
  position: sticky;
  top: 0px;
  text-transform: capitalize;
}

.sitetable tbody tr td {
  border: 0px;
  border-top: 0px;
  color: #6e6e6e;
  font-size: 16px;
  padding: 11px 14px;
  font-weight: 500;
  white-space: nowrap;
  vertical-align: middle;
}

[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  width: auto;
  visibility: visible;
  max-width: 100%;
  max-height: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.simplebar-content:before,
.simplebar-content:after {
  content: " ";
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  position: absolute;
  left: 0;
  right: 0;
  min-height: 10px;
}

.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  background: #00000040;
  border-radius: 7px;
  left: 2px;
  right: 2px;
  opacity: 0;
  transition: opacity 0.2s linear;
}

.simplebar-scrollbar.simplebar-visible:before {
  opacity: 0.5;
  transition: opacity 0s linear;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 3px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}

[data-simplebar-direction="rtl"] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}

.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.t-ligh-gray {
  color: #07d6d6;
}

.siteformbox label {
  color: #121212 !important;
  font-size: 17px;
  font-weight: 500;
  width: 100%;
  margin-bottom: 10px;
}

.siteformbox .form-control {
  color: #121212 !important;
  font-size: 14px !important;
  font-weight: 400;
  height: 46px;
  appearance: auto;
  border: 1px solid #e4e4e4;
  background-color: #fff;
  border-radius: 10px;
}

.siteformbox select.form-control,
.searchfrmbg .dateinput .form-control,
.searchfrmbg .dateinput .input-group-text,
.modal .form-control {
  border: 1px solid #e4e4e4;
  background-color: #fff;
}

.form-outline .form-control ~ .form-notch div {
  border: 1px solid #e4e4e4;
}

.siteformbox .input-group-text {
  height: 46px;
  border: 1px solid #e4e4e4;
  color: #121212;
  font-size: 14px;
  margin: 0px !important;
  background-color: #fff;
  border-radius: 0px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-left: -1px;
}

.form-outline .form-control ~ .form-notch,
.form-outline .form-control.form-control-lg ~ .form-label {
  z-index: 9;
  color: #121212;
}

input:focus,
textarea:focus,
.form-control:focus {
  box-shadow: none;
  outline: none;
}

.siteformbox .form-group {
  margin-bottom: 22px;
  position: relative;
}

.sitebtn,
.border-btn,
.whitebtn {
  border: 0px;
  background: linear-gradient(45deg, #ff00c7, #ff9ffb);
  color: #fff;
  font-size: 18px;
  padding: 10px 25px;
  border-radius: 5px;
  text-transform: capitalize;
  font-weight: 500;
  transition: all 0.5s ease-in-out;
}

.sitebtn:hover,
.sitebtn:focus,
.sitebtn:active {
  background: linear-gradient(90deg, #ff00c7, #ff9ffb);
  color: #fff !important;
  border: 0px;
  text-decoration: none;
}
.whitebtn,
.whitebtn:hover,
.whitebtn:focus,
.whitebtn:active {
  background: #fff;
  color: #ff00c7;
}
.border-btn {
  background: none;
  border: 2px solid #ff00c7;
  color: #ff00c7;
  padding: 8px 25px;
}

.border-btn:hover,
.border-btn:focus,
.border-btn:active {
  border: 2px solid #ff00c7;
  background: none;
  color: #ff00c7;
}

.tabbanner li a {
  color: #121212 !important;
  border: 0px !important;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 16px !important;
  padding: 5px 16px !important;
  border-radius: 5px !important;
  background: #f3f3f3 !important;
}

.tabbanner li a:hover {
  background-color: #f3f3f3 !important;
}

.tabbanner li.nav-item a.active {
  background: #3f023b !important;
  color: #fff !important;
}

.tabbanner {
  border-bottom: 0px;
  white-space: nowrap;
  margin-bottom: 0px;
  overflow: hidden;
  padding: 0;
}

.tabbanner li {
  padding: 2px;
}
.tabbanner > li:first-child {
  padding-left: 0px;
}
.subnotes {
  font-size: 14px;
  font-weight: 500;
  margin: 0px;
  padding-bottom: 17px;
  padding-top: 0;
  line-height: 24px;
  color: #f7f7f7;
}

.green-btn,
.green-btn:hover,
.green-btn:focus,
.green-btn:active {
  background: #28b741 !important;
  color: #fff !important;
}

.red-btn,
.red-btn:hover,
.red-btn:focus,
.red-btn:active {
  background: #f75151 !important;
  color: #fff;
}

.view-btn,
.btn-sm {
  padding: 10px 12px;
  font-weight: 500;
  font-size: 15px;
  border-radius: 5px;
  line-height: normal;
  background: none;
  border: 1px solid #ff00c7;
  color: #ff00c7;
}
.view-btn:hover,
.view-btn:focus,
.view-btn:active,
.btn-sm:hover,
.btn-sm:focus,
.btn-sm:active {
  background: none;
  border: 1px solid #ff00c7;
  color: #ff00c7 !important;
}
.sitescroll {
  max-height: 750px;
}

input::placeholder {
  color: #ccc !important;
  font-size: 17px;
}

.iconbtn {
  font-size: 19px;
}
.iconbtn:hover,
.iconbtn:focus,
.iconbtn:active {
  color: #ff00c4;
}
.gridparentbox {
  padding-top: 40px;
  padding-bottom: 30px;
}

.convertbox a {
  color: #121212;
  width: 100%;
  display: inline-block;
}

.coinicon {
  width: 34px;
  margin-right: 9px;
  position: relative;
  top: -1px;
}

.modalbgt .modal-content {
  background-color: #ffffff;
  box-shadow: none;
  border-radius: 20px;
  border: 0px;
}

.modalbgt .modal-title {
  font-size: 24px;
  color: #121212;
  font-weight: 500;
  width: 100%;
  padding-top: 8px;
  font-family: "League Spartan", sans-serif !important;
  text-align: center !important;
  border-bottom: 1px dashed #ccc;
  padding-bottom: 15px;
}

.modalbgt .modal-body {
  padding: 34px 35px;
  padding-top: 26px;
}

.modalbgt .modal-header {
  padding: 35px 35px;
  border-bottom: 0px;
  padding-bottom: 0px !important;
  padding-top: 13px;
}

.modalbgt .modal-footer {
  border-top: 0px;
  display: inline-block;
}

.close,
.close:hover,
.close:focus {
  outline: none;
}

.modalbgt .modal-header .close {
  font-size: 27px;
  padding: 0;
  line-height: normal;
  width: 39px;
  height: 39px;
  background: #ff00c7 !important;
  border-radius: 50px;
  color: #fff !important;
  padding: 0px;
  padding-top: 5px;
  display: inline-block;
  position: absolute;
  right: -11px;
  top: -13px;
}

.modalbgt .modal-header .close,
.modalbgt .modal-header .close:hover,
.modalbgt .modal-header .close:focus,
.modalbgt .modal-header .close:active {
  color: #fff;
  opacity: 1;
  background: none;
  border: 0px;
}

.modal-backdrop {
  background-color: #110010f5;
}

.modal-backdrop.show {
  opacity: 0.8;
}

.cryptoselect,
.cryptomodal ul {
  padding: 0px;
  margin: 0px;
  max-height: 360px;
}

.cryptoselect li,
.cryptomodal li {
  list-style: none;
  float: none;
  padding: 13px 13px;
  font-size: 17px;
  font-weight: 500;
}
.cryptomodal li b {
  font-weight: 400;
}
.paymentlogo {
  padding: 0px;
  margin: 0px;
  justify-content: center;
  display: flex;
}

.paymentlogo li {
  width: 50%;
  float: left;
  border-bottom: 0px;
  list-style: none;
  padding: 0px 10px;
}

.cryptoselect li .text-right {
  float: right;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
}

.paymentlogo li img {
  width: 100%;
  max-width: 123px;
  text-align: center;
  display: block;
  margin: 0px auto;
}

.cryptobg {
  border: 1px solid #e4e4e4;
  background-color: #fcfcfc;
  border-radius: 10px;
  padding: 15px 15px;
}

.cryptobg .form-control,
.cryptobg .input-group-text {
  background: none;
  border: 0px;
  padding: 9px 17px;
  height: auto;
  text-align: right;
  background: #3d85c6;
  border-radius: 8px !important;
  font-size: 13px;
}

.cryptobg .form-control {
  background: none;
  padding: 6px 14px;
  width: 100%;
  border-radius: 9px !important;
  margin-left: auto !important;
  font-size: 24px !important;
  font-weight: 600;
  height: 50px;
  padding-right: 0px;
}

.cryptobg .form-control:focus {
  outline: none;
  border: 0px !important;
  outline-offset: 0px !important;
  box-shadow: none !important;
}

.cryptoselect li:hover,
.cryptoselect li.active {
  background: #e6e6e6;
  border-radius: 5px;
}

.cryptomodal .modal-body,
.cryptomodal .modal-header {
  padding: 20px 20px;
}

.cryptomodal .contentbox {
  padding: 6px 0px;
}

.cryptomodal .form-group {
  margin-bottom: 15px;
}

.centerboxtop {
  width: 100%;
  max-width: 460px;
  margin-top: 12px;
}

footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0px;
  height: 77px;
  z-index: 9;
}

.footer-gray-bg {
  padding: 20px 0px;
}

.footer-gray-bg hr {
  border-top: 1px solid #ffffff26 !important;
  margin: 30px 0px;
}

.ftext {
  margin: 0;
  padding: 0px 0;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  padding-top: 5px;
}
.homefooter .ftext {
  color: #bc8fba;
  margin-top: 40px;
}
.foot-list {
  padding: 0px;
  margin: 0px;
}

.footlofo img {
  width: 100%;
  max-width: 138px;
  margin-bottom: 20px;
}

.foot-list li {
  list-style: none;
  float: left;
  width: 100%;
  padding-bottom: 16px;
}

.foot-list li:last-child {
  padding-right: 0px;
  border-right: 0px;
}

.foot-list li a {
  color: #bc8fba;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  padding: px 0px;
  text-decoration: none;
  width: 100%;
  display: inline-block;
}

.foot-list li a:active,
.foot-list li a:focus,
.foot-list li a:hover {
  text-decoration: none;
}

.footer-gray-bg p,
.footer-gray-bg p a {
  margin-bottom: 4px;
  color: #121212;
  font-size: 17px;
  font-weight: 400;
}

.footer-gray-bg .flogo {
  width: 100%;
  max-width: 229px;
}
.sociallist {
  float: right;
}

.sociallist li:last-child a i {
  margin-right: 0;
}

.sociallist li {
  list-style: none;
  display: inline-block;
  padding: 0px 0px;
}

.sociallist li a i {
  font-size: 13px;
  color: #ff00c4;
  border-radius: 4px;
  width: 30px;
  height: 29px;
  margin-right: 4px;
  border: 1px solid #ff00c7;
  text-align: center;
  padding-top: 7px;
}

.sociallist {
  padding: 0;
  margin: 0px;
}

.social-bg {
  width: 100%;
  display: inline-block;
  text-align: left;
  margin-top: 10px;
}

.social-bg .heading-title {
  font-size: 14px !important;
  font-weight: 400;
  margin-top: 6px;
  padding-bottom: 0;
}

.footerbottombg .sociallist {
  float: right;
}

.homefooter .sociallist {
  float: none;
  margin-top: 22px;
}

.footerbottombg {
  border-top: 1px solid #171919;
}

.footerbottombg .social-bg {
  padding-top: 12px;
}

.subscibefrm .form-control {
  height: 42px;
  background: none;
  border: 1px solid #d09acd;
  border-radius: 5px !important;
  color: #3f023b;
  font-size: 16px;
  font-weight: 400;
  margin-top: 2px;
}

.subscibe .input-group-text {
  height: auto;
  background: none;
  border: 0px;
  padding-right: 0px;
}

.subscibefrm .btn {
  color: #fff;
  border: 1px solid #600f5a;
  background: #600f5a;
  margin-top: 14px;
}

.footer-gray-bg h3 {
  color: #ffffff;
  font-size: 25px;
  text-transform: capitalize;
  font-weight: 600;
  width: 100%;
  margin-bottom: 20px;
  display: inline-block;
}

.blancetext span {
  font-size: 15px;
}

.headbg .iconbtn {
  font-size: 13px !important;
}

.iconbtn img {
  margin-right: 5px;
}

.innerpagecontent {
  position: relative;
  text-align: center;
  margin-bottom: 21px;
}

.innerpagecontent h3 {
  font-size: 27px;
  margin: 0px;
  font-weight: 600;
  font-family: "League Spartan", sans-serif !important;
  text-align: left;
  color: #120d12;
  padding: 0px !important;
}

.innerpagecontent p {
  font-size: 20px;
}

.settinglimit li {
  list-style: none;
  float: left;
  vertical-align: top;
}

.settinglimit li a {
  border: 1px solid #f3f3f3;
  text-align: center;
  padding: 5px 18px;
  background-color: #f3f3f3;
  margin-left: 4px;
  cursor: pointer;
  font-size: 15px;
  border-radius: 10px;
}

.settinglimit li.active a,
.settinglimit li a:hover,
.settinglimit li a:focus {
  background-color: #3f023b !important;
  color: #fff !important;
  border-color: #3f023b !important;
  opacity: 1;
}

.settinglimit {
  width: 100%;
  display: flex;
  margin: 0px;
  padding: 12px 0px;
}

li.inputsearch a {
  padding: 0px !important;
  background: none;
  border: 0px;
  margin: 0px;
}

li.inputsearch .form-control,
li.inputsearch .input-group-text {
  height: 43px !important;
  margin-left: 3px;
  line-height: normal;
  min-height: inherit;
  margin-top: -9px !important;
}

.lightdark {
  border: 1px solid #f3f3f3;
  background-color: #f3f3f3;
}

.balancetable {
  display: inline-block;
  width: 100%;
  padding: 10px 10px;
  border-radius: 10px;
}

.balancetable > div {
  display: inline-block;
  width: 100%;
  padding: 5px 6px;
  text-align: left;
}

.balancetable .h4 {
  font-size: 16px;
  margin-bottom: 4px;
}

.balancetable .h5 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
  float: right;
  position: relative;
  top: 5px;
}

.alert-success {
  background-color: rgb(54 235 128 / 13%);
  border: 1px solid #34a964;
}

.alert-warning {
  background-color: #523d00;
  border: 1px solid #a67d04 !important;
}

.alert-info {
  background-color: #1dc4ff26;
  border: 1px solid #1dc4ff !important;
}

.alert-danger {
  background-color: rgb(249 89 89 / 14%);
  border: 1px solid #f95959 !important;
}

.alert {
  color: #fff;
  font-size: 13px;
  padding: 16px 17px;
  border-radius: 10px;
}

.successicon {
  text-align: center;
}

.successicon i {
  font-size: 43px;
  margin: 18px 0px;
  border: 1px solid #4fb61b;
  border-radius: 50px;
  padding: 12px 24px;
  color: #4fb61b;
}

.borderbox {
  border: 1px solid #2b2d5b;
}

.btn-gray,
.btn-gray:hover,
.btn-gray:active {
  background: #5566a1;
  color: #fff;
}

.btn-block + .btn-block {
  margin-top: 0;
}

.convertbox i {
  /* float: right; */
  position: relative;
  /* top: 2px; */
  /* right: 3px; */
}

.cryptobg .input-group-text i img {
  width: 100%;
  max-width: 18px;
  margin-left: 10px;
  position: relative;
  top: -3px;
  filter: grayscale(1) invert(1);
}

.importpoolbox {
  padding: 0px;
}

.importpoolbox a {
  padding: 15px 15px;
}

.profiledrop a.dropdown-bs-toggle,
.coinselectbox a.dropdown-bs-toggle,
.userheader .custom-menu-li-style .btn {
  background: linear-gradient(45deg, #ff00c7, #ff9ffb);
  padding: 8px 17px !important;
  border-radius: 5px;
}
.profiledrop a.dropdown-bs-toggle {
  color: #fff !important;
}
.headbg ul .profiledrop a.dropdown-toggle:hover,
.headbg ul .profiledrop a.dropdown-toggle:focus {
  color: #fff !important;
}

.profiledrop a.dropdown-toggle img {
  width: 100%;
  max-width: 18px;
  margin-right: 7px;
}

.iconbtn.dropdown-toggle:after {
  display: none;
}

.headbg ul li.dropdown.active .dropdown-menu a {
  color: #fff !important;
}

body.modal-open,
.modal {
  padding-right: 0px !important;
}

.tkenmodal .coinicon {
  width: 38px;
  float: left;
}

.tkenmodal .h4 {
  font-size: 15px;
}

.form-check-input[type="checkbox"]:checked {
  background-image: none;
  background-color: #f5f2ff;
}

.form-switch .form-check-input {
  background-color: #626e9f;
}

.form-switch .form-check-input:checked[type="checkbox"]:after,
.form-check-input[type="checkbox"]:checked {
  background-color: #9e83f5;
}

.cryptomodal .coinicon {
  float: none;
}

.tooltipnotes {
  padding: 5px 17px;
  display: inline-block;
  padding-left: 3px;
}

.notesbox {
  display: none;
  position: absolute;
  background: #e2e2e2;
  border-radius: 5px;
  padding: 2px 10px;
  font-size: 14px;
  line-height: 23px;
  color: #121212;
  font-weight: 400;
  z-index: 99;
  border: 1px solid #e2e2e2;
  margin-left: 11px;
  margin-top: -8px;
  margin-right: 10px;
}

.tooltipnotes:hover .notesbox {
  display: inline-block;
}

.notesbox::before {
  content: "\f0d9";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  color: #e2e2e2;
  font-size: 24px;
  padding-right: 0.5em;
  position: absolute;
  top: 3px;
  left: -9px;
}

.tooltipnotes i {
  color: #aeaeae;
}

.centermiddlebox {
  min-height: 68vh;
}

.table-responsive-stack td,
.table-responsive-stack th {
  white-space: nowrap;
}

.table-responsive-stack th {
  border-bottom: 0px !important;
}

.modal p {
  margin-bottom: 3px;
}

.connectedwallet {
  display: none;
}

.leftsidemenu {
  background: #3f023b;
  position: fixed !important;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 120px;
  z-index: 9999 !important;
  padding: 19px 0px;
}

.leftsidemenu .logo,
.leftsidemenu.active .logoicon {
  display: none;
}

.leftsidemenu.active .logo {
  display: block;
}

.leftsidescroll {
  height: 100%;
  display: table;
  width: 100%;
}

.leftsidescroll > ul {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
}

.leftsidescroll .iconview {
  color: #ffffff;
  padding-top: 0;
  border-radius: 7px;
  width: 41px;
  text-align: center;
  display: inline-block;
}

.leftsidescroll i {
  font-size: 16px;
  position: relative;
  width: 65px;
  height: 60px;
  background: #560b51;
  display: inline-block;
  border-radius: 5px;
  padding-top: 15px;
  margin-bottom: 9px;
}

.leftsidemenu li a.active i {
  background: #ff00c7;
}

.leftsidemenu ul {
  padding: 0px;
  margin: 0px;
}

.leftsidemenu li {
  list-style: none;
  text-align: center;
  width: 100%;
  display: inline-block;
  padding-bottom: 4px;
}

.leftsidemenu li .nav-link {
  padding: 9px 10px;
  color: #fff;
  font-size: 15px;
  display: inline-block;
  width: 100%;
  position: relative;
  text-transform: capitalize;
  font-weight: 600;
  font-family: "League Spartan", sans-serif !important;
}

.leftsidemenu li .nav-link:hover {
  text-decoration: none;
}

.leftsidemenu li .nav-link.active,
.leftsidemenu li .nav-link.active:hover,
.leftsidemenu li .nav-link.active:focus,
.leftsidemenu li .nav-link.active:active,
.leftsidemenu li .nav-link:hover {
  color: #fff;
  background: none;
  border-radius: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.leftsidemenu li img {
  width: 26px;
}

.leftsidemenu li a > div,
.pagewrapperbox .tradepage .leftsidemenu.active {
  display: inline-block;
}
.leftsidemenu li .nav-link > div {
  width: 100%;
  display: none;
}
.leftsidemenu li .nav-link:hover > div {
  display: inline-block;
  position: absolute;
  width: auto;
  left: 114px;
  background: #83327e;
  top: 17px;
  padding: 7px 20px;
  border-radius: 5px;
  white-space: nowrap;
}

.leftsidemenu li .nav-link:hover > div::after {
  content: "\f0dd";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  color: #83327e;
  font-size: 26px;
  padding-right: 0.5em;
  position: absolute;
  top: 5px !important;
  left: -10px !important;
  transform: rotate(92deg);
}

.leftsidemenu {
  display: inline-block;
}

.pagewrapperbox .leftsidemenu.active {
  display: block;
}
.closeiconbtn {
  padding-bottom: 0;
  position: absolute;
  top: 4px;
  right: -8px;
  background: #ff00c7;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  color: #fff;
  text-align: center;
  padding-top: 4px;
  display: none;
  cursor: pointer;
}

.closeiconbtn .btn {
  position: relative;
  color: #50fcd3;
  z-index: 9;
  border-radius: 50px;
  padding: 13px 21px;
  top: inherit;
  left: inherit;
  cursor: pointer;
  font-size: 14px;
  text-align: left;
  width: 100%;
  display: inline-block;
}

.closeiconbtn .btn i {
  top: inherit;
  position: relative;
  right: 0;
  font-size: 14px;
  margin-right: 10px;
}
.userheader .container {
  width: 100%;
  max-width: 100%;
}
.userheader {
  border-bottom: 1px solid #00000014;
  padding: 20px 0px;
}
.gridparentbox,
footer,
.userheader {
  padding-left: 150px;
  padding-right: 27px;
}

.priceboxflex,
.priceboxflex .owl-stage,
.priceflexbox {
  display: flex;
  width: 100%;
}

.priceboxflex .priceboxouter,
.priceboxflex .owl-stage > div,
.priceflexbox > div {
  width: 100% !important;
  margin: 5px 10px;
  padding: 0;
  border-radius: 20px;
  height: 100%;
}
.priceflexbox > div:first-child {
  margin-left: 0px;
}
.priceflexbox > div:last-child {
  margin-right: 0px;
}
.priceflexbox.owl-carousel .owl-item {
  padding: 0px !important;
}

.priceflexbox .owl-stage .owl-item > div {
  padding: 5px;
}

.priceflexbox .owl-stage .owl-item:first-child > div {
  padding-left: 0px !important;
}

.priceflexbox .owl-stage .owl-item:last-child > div {
  padding-right: 0px !important;
}

.priceboxflex .owl-stage .priceboxouter {
  background: none;
  margin: 0px;
}

.priceboxouter .panelcontentbox {
  box-shadow: 8px 8px 0px #ff13cd21;
}

.priceboxflex > div:first-child {
  margin-left: 0px;
}

.priceboxflex > div:last-child {
  margin-right: 0px;
}

.priceboxflex .owl-stage {
  padding-top: 0px;
}

.priceboxflex .panelcontentbox {
  z-index: 99;
  height: 100%;
  overflow: visible;
}

.livepricetable {
  display: table;
  width: 100%;
  padding: 24px;
  text-align: left;
}

.livepricetable > div {
  display: table-cell;
  vertical-align: middle;
}

.livepricetable > div:last-child {
  width: 150px;
  text-align: right;
}

.livepricetable .h6 {
  font-size: 14px;
  margin: 0px;
  padding-top: 4px;
}

.chartline img {
  width: 100%;
}

.livepricetable img {
  width: 100%;
  max-width: 66px;
  display: inline-block !important;
}

.livepricetable .h4 {
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  color: #b1b1b1;
  margin: 0px;
}

.livepricetable .h5 {
  font-size: 14px;
  font-weight: 700;
}

.livepricetable .h3 {
  font-size: 22px;
  font-weight: 600;
  padding-top: 17px;
  margin: 0px;
  color: #272727;
  display: inline-block;
}

.livepricetable .h3 .h5 {
  font-size: 13px;
  padding-left: 10px;
}

.pricerowbox {
  display: inline-block;
  width: 100%;
  margin-bottom: 7px;
}

.flexbox {
  display: flex;
  width: 100%;
}

.flexbox > div {
  width: 100%;
  margin: 10px;
}

.flexbox > div:first-child {
  margin-left: 0px;
}

.flexbox > div:last-child {
  margin-right: 0px;
}

.topcontentbox {
  width: 100%;
}

.innerpagecontent .container {
  position: relative;
  z-index: 9;
}

.coinleft {
  float: left;
}

.sitecontainer {
  width: 100%;
  max-width: 100%;
}

.paneldarkbox {
  background: none;
  border-radius: 10px;
  padding: 25px;
  border: 1px solid #e4e4e4;
}

.alertboxcenter {
  height: 100%;
  display: table-cell;
  width: 100%;
  text-align: center;
  vertical-align: middle;
}

.alertboxcenter > div {
  padding: 20px;
}

.alertboxcenter h4 {
  font-size: 23px;
  color: #121212;
  padding-bottom: 14px;
  opacity: 0.5;
}

.swapbox {
  position: relative;
}

.swapexchange .flexbox > div {
  margin: 0px 20px;
}

.swapexchange .flexbox > div:first-child {
  margin-left: 0px;
}

.swapexchange .flexbox > div:last-child {
  margin-right: 0px;
}

.btncenterbox {
  display: flex;
}

.btncenterbox .btn {
  width: 47%;
  margin: 0px auto;
}

.btncenterbox .btn.border-btn {
  margin-right: 22px;
}

.swapexchange .heading-box,
.liquiditypage .heading-box {
  padding: 0px;
  padding-bottom: 20px;
  border: 0px;
}

.swapbox i img {
  width: 100%;
  max-width: 42px;
}

.cryptobg .pull-right {
  white-space: nowrap;
  font-size: 15px;
  float: none;
  display: flex;
  justify-content: end;
}

.labelinput {
  font-size: 15px;
}

.cryptobg .settinglimit {
  padding: 0px;
}

.labelbox {
  margin-bottom: 17px !important;
}

.cryptobg .settinglimit li a {
  background: #e2e2e2;
  color: #333;
  border-color: #f3f3f3;
  /* opacity: 0.3; */
  padding: 5px 9px;
  font-weight: 400;
  border-radius: 5px;
}

.cryptobg .settinglimit li.active a,
.cryptobg .settinglimit li a:hover,
.cryptobg .settinglimit li a:focus {
  background: #3f023b !important;
  opacity: 1;
  color: #fff !important;
  border-color: #3f023b !important;
}

.swapbalance {
  padding: 0px;
}

.swapbalance .h4,
.swapbalance .h5 {
  font-size: 16px;
  font-weight: 600;
}

.swapbox .tabrightbox {
  top: 0px;
  right: 0px;
}

.suucessmap {
  display: table;
  position: relative;
  width: 100%;
}

.suucessmap::before {
  content: "";
  position: absolute;
  top: 24px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-top: 2px dashed #00d4a4;
}

.suucessmap > div {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  width: auto;
  float: none;
}

.suucessmap > div:first-child::before,
.suucessmap > div:last-child::before {
  content: "";
  position: absolute;
  top: 24px;
  left: 0px;
  width: 51%;
  height: 100%;
  border-top: 11px solid #ffffff;
}

.suucessmap > div:last-child::before {
  left: inherit;
  right: 0px;
}

.suucessmap .coinicon {
  width: 54px;
}

.suucessmap .btn {
  border-radius: 50px;
  width: 32px !important;
  height: 32px;
  display: block;
  text-align: center;
  margin: 0px auto;
  line-height: normal;
  padding: 0px;
  padding-top: 6px;
  font-size: 14px;
}

.btn-success,
.btn-success:hover,
.btn-success:focus,
.btn-success:active {
  background: #00d4a4;
  border-color: #00d4a4;
}

.confirmbox h5,
.alertboxcenter h5 {
  font-size: 19px;
}

.stackpage .coinicon {
  width: 32px;
}

.stackpage table tbody tr td {
  padding: 9px 5px;
  white-space: inherit;
  line-height: 21px;
  font-size: 16px;
  font-weight: 400 !important;
}

.stackpage table tbody tr > td:first-child {
  text-align: left;
}

.stackpage table tbody tr > td:last-child {
  text-align: right;
  color: #212121;
  font-weight: 500 !important;
}

.stackpage .stakeicon > div {
  display: table-cell;
  vertical-align: middle;
  color: #212121;
}

.stackpage .panelcontentbox {
  padding: 11px 14px;
  padding-bottom: 5px;
  min-height: 352px;
}

.buytradedata > td {
  padding: 0px !important;
}

.alink img {
  width: 15px;
}

.stackpage > div {
  margin-bottom: 20px;
  padding: 0px 7px;
}
.stakedtab {
  margin-bottom: 13px;
  padding: 12px 14px;
}

.supportsearch .siteformbox .form-control,
.supportsearch .siteformbox .input-group-text {
  border-radius: 6px !important;
}

.supportsearch .siteformbox .input-group-text {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.supportsearch {
  padding: 10px;
  padding-bottom: 0px;
}
.supportsearch i {
  position: relative;
  top: -2px;
}
.stakedtab .tabrightbox {
  top: 0px;
  right: 0px;
  display: flex;
}

.supportsearch .siteformbox .form-control {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.cardlist ul {
  border: 0px !important;
  padding-top: 2px;
}

.cardlist ul li a {
  background: none !important;
  color: #121212;
  border: 0px !important;
  padding: 8px 13px;
  font-size: 15px;
  font-weight: 400;
}

.cardlist ul li a:hover,
.cardlist ul li a:focus,
.cardlist ul li a:active {
  color: #121212;
}

.cardlist ul li a.active {
  color: #ff00c7 !important;
  cursor: not-allowed;
}

.cardlist ul li a i {
  margin-right: 4px;
}

.cardlist .fa-bars {
  transform: rotate(90deg);
}

.stackcardbox > div {
  width: 100% !important;
  max-width: 100% !important;
  flex: 100% !important;
}

.stackcardbox tr {
  display: table-cell;
  width: auto;
  padding: 23px 13px;
}

.stackcardbox table tbody tr td {
  padding: 4px 8px;
  display: block;
  white-space: nowrap;
}

.stackcardbox table {
  display: flex;
  width: 100%;
  margin: 0px;
}

.stackcardbox .buytradedata table {
  background: #fff;
}

.stackcardbox tr td {
  width: 100%;
  display: block;
  text-align: left !important;
}

.stackcardbox tbody {
  display: table;
  width: 100%;
}

.stackcardbox hr {
  display: none;
}

.stackcardbox .buytradedata {
  display: table-caption;
  width: 100%;
  padding: 0px;
}
.stackcardbox .buytradedata table {
  border-top: 1px dashed #dedede;
}
.stackcardbox .buytradedata > td {
  width: 100%;
}

.stackpage .btn {
  width: 100%;
  font-size: 16px;
  padding: 10px 12px;
}

.liquidtybalance .form-group {
  margin-bottom: 33px;
}

.liquidtybalance .blancetext > .form-group:last-child {
  margin-bottom: 0px;
}

.liquidtybalance > div:first-child span {
  font-size: 14px;
  padding-bottom: 11px;
  display: block;
}

.liquidtybalance p > span:last-child {
  text-align: right;
  float: right;
}

.liquiditypage .btn-block {
  width: 100%;
}

.blancetext p {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 400;
}
.liquidtybalance .t-blue {
  color: #9ba1b1;
}
.blancetext p > b {
  font-weight: 600;
}

.liquiditypage .swapbox {
  border-right: 0px;
  padding-right: 17px;
}

.liquiditypage .swapbox .tabrightbox {
  right: 18px;
}

.liquiditypage .settinglimit li a {
  opacity: 1;
}

.stakelpmodal .settinglimit {
  padding: 0px;
  padding-right: 10px;
}

.stakelpmodal .stakebtnrow .btn {
  width: 50%;
}

.bluedarkbox {
  background: #f3f3f3;
  border-radius: 10px;
  padding: 25px;
  display: inline-block;
  width: 100%;
}

.stakelpmodal .bluedarkbox {
  padding: 0px;
}

.stakelpmodal .table tr td {
  padding: 18px 21px;
}

.lockicon {
  width: 100%;
  max-width: 70px;
  display: block;
  margin: 0px auto;
  margin-bottom: 12px;
}

.table > :not(:first-child) {
  border-top: 0px !important;
}

.roimodal thead tr th {
  border-bottom: 0px !important;
}

.roimodal thead tr > th:last-child,
.roimodal tbody tr > td:last-child {
  text-align: right;
}

.roimodal thead tr > th:nth-child(2),
.roimodal tbody tr > td:nth-child(2) {
  text-align: center;
}

.boxheight {
  height: 100%;
  display: table;
  width: 100%;
}

.coinlefticon {
  position: relative;
  margin-left: -13px;
}

.skyblue,
.skyblue:hover,
.skyblue:focus,
.skyblue:active {
  background: #16c2f6;
  color: #27243b !important;
}

.stakeflexbox .sitescroll {
  max-height: 500px;
  min-height: 200px;
}

.walletpage .flexbox > div:first-child {
  width: 40%;
}

.walletpage .balancetable {
  padding: 0px;
}

.stakebtnrow > .btn {
  width: 100%;
  margin-bottom: 11px;
}

.roimodal .modal-body {
  padding: 30px 20px;
}

.stackcardbox tr.cellbutton {
  width: 249px;
}

.mobiletoggle button {
  padding: 2px 10px;
  border: 0px;
  background: none;
}

.mobiletoggle img {
  width: 18px;
}

.mobiletoggle {
  display: none;
}

.databottomrow > td {
  padding-bottom: 15px !important;
}

.stakedlppage .stackcardbox .alink img {
  margin-left: 0px !important;
  margin-right: 3px !important;
}

.swapxchange > div {
  display: none;
}

.exchangewallet > div:first-child {
  display: table;
}

.exchangesuccess > div:last-child {
  display: table;
}

.exchangedetails > div:nth-child(2) {
  display: block;
}

.stakepanel .heading-box {
  padding: 15px 0px;
  border: 0px;
}

.smallcoin {
  width: 20px;
}

.cryptobg .input-group {
  flex-wrap: inherit;
}

.cryptobg .input-group-text {
  background: none !important;
  padding: 0px !important;
  height: auto !important;
}

.cryptobg .input-group > span {
  width: 100%;
  font-weight: 500;
}

.cryptobg .input-group > span:first-child {
  text-align: left !important;
}

.convertbox {
  border: 0px;
  padding: 13px 0px;
  height: auto;
  text-align: left;
  border-radius: 10px !important;
  font-size: 20px;
  min-width: 117px !important;
  min-height: 50px;
  font-weight: 500;
}

.cryptobg .input-group > span > span {
  /* width: 100%; */
}

.alertboxcenter .btn {
  width: 100%;
  max-width: 349px;
  margin: 0px auto;
}

.transheight.settinglimit li a {
  padding: 14px 18px;
}

.tolerhight.settinglimit li a {
  padding: 11px 18px;
}

.stakeicon hr {
  margin: 0px;
  margin-top: 13px;
}

.liquiditybox .btn {
  background: #140d32;
}

.profiledrop .dropdown-toggle::after {
  display: none;
}

.sttingdealine > p:first-child {
  width: 320px;
}

.lowopacity {
  cursor: not-allowed;
}

.stakedlppage .stackpage .panelcontentbox {
  min-height: 221px;
}

.stakedlppage .stackpage table tbody .databottomrow > td:last-child {
  font-size: 17px;
  font-weight: 500;
}

.buytradedata table {
  margin-bottom: 0px;
}

.buytradedata .alink {
  font-size: 15px;
  color: #067700;
}

.buytradedata .alink img {
  margin-right: 3px !important;
}

.stakedlppage .buytradedata td {
  padding: 3px 4px;
}

.stakebannerpage #completed .stackcardbox .buytradedata table tr td {
  display: table-cell;
  width: auto;
}

.stackcardbox .panelcontentbox {
  min-height: inherit !important;
  padding: 0px 0px;
  overflow: hidden;
}

.stakedlppage .buytradedata table tr td {
  display: table-cell;
  width: auto;
}

.stakedlppage .stackcardbox .buytradedata tr.cellbutton {
  xwidth: auto;
}

.stakedlppage .stackcardbox .buytradedata tr td .btn {
  padding: 10px 41px;
}

.stakedlppage .databottomrow .stakeicon > div:last-child {
  text-align: right;
  font-weight: 600;
}

.stakedlppage .stakeicon {
  display: table;
  width: 100%;
}

.stakedlppage .stackcardbox .stakeicon {
  display: inherit;
}

.stakedate {
  display: table;
  width: 100%;
}

.stakedate > div {
  display: table-cell;
  vertical-align: top;
  padding-bottom: 10px;
}

.stakedate > div:last-child {
  text-align: right;
}

.liquiditypage .swapbox .form-control,
.buytokenpage .swapbox .form-control {
  background: none !important;
  /* height: auto !important; */
  padding: 0px !important;
}

.buytokenpage .swapbox .form-control {
  font-size: 30px !important;
}

.liquiditypage .swapbox .input-group-text > div:first-child {
  width: 200px;
  text-align: left;
}

.liquiditypage .swapbox .input-group-text > div:last-child {
  padding-left: 30px;
}

.liquiditypage .labelbox {
  margin-bottom: 0px !important;
}

.importpoolbox .convertbox {
  text-align: left;
}

.importpoolpage .alertboxcenter h4 {
  font-size: 15px;
}

.liquiditybox a.sitebtn[aria-expanded="true"] {
  display: none;
}

.buytokenpage .buywallet > div:first-child {
  display: inline-block;
  width: 100%;
}

.buytokenpage .buysuccess > div:last-child {
  display: table;
}

.confirmbtn {
  display: none;
}

.unlockbtn .btn {
  width: 100%;
}

.tokentable {
  overflow: hidden;
  padding: 10px;
}

.tokentable table tr td,
.tokentable table tr th {
  text-align: center;
}

.tokentable table tbody > tr:nth-child(odd) {
  background: #1f1a44;
}

.buytokenpage .convertbox {
  opacity: 0.5;
}

.buytokenpage .convertbox.active {
  opacity: 1;
}

.stakepanel .sitetable tbody tr td {
  border-bottom: 1px solid #21164c !important;
}

.buytradedata table tr td {
  padding: 5px 2px !important;
}

.moreicon[aria-expanded="true"] img {
  transform: rotate(180deg);
}

.stakepanel {
  position: relative;
}

.stakepanel .tabrightbox {
  right: 13px;
}

.stakepanel .panelcontentbox {
  padding: 20px 20px;
  min-height: 292px;
}

.swapexchange .convertbox {
  min-width: 160px !important;
}

.moreicon,
.moreicon:hover,
.moreicon:focus,
.moreicon:active,
.stakepanel .alink,
.stakepanel .alink:hover,
.stakepanel .alink:focus,
.stakepanel .alink:active {
  color: #ff00c7;
}

.blancetext .fa-refresh {
  color: #fff;
}

.tokentable tr td a {
  color: #fff;
  text-decoration: underline;
}

.tokentable .coinicon {
  margin-right: 10px;
}

.swapbalance i img {
  width: 100%;
  max-width: 12px;
}

.buytokenpage .swapbox .siteformbox > div:nth-child(2) {
  margin-bottom: 15px;
}

.buytokenpage .blancemobile {
  margin-bottom: 35px;
}

.settingmodal .settinglimit {
  display: flex;
  padding: 0px;
  margin-bottom: 16px;
}

.settingmodal .settinglimit li {
  width: 100%;
  padding: 0px 4px;
}

.settingmodal .settinglimit > li:first-child {
  padding-left: 0px;
}

.settingmodal .settinglimit > li:last-child {
  padding-right: 0px;
}

.settingmodal .settinglimit li a {
  width: 100%;
  display: inline-block;
  margin: 0px;
}

#connectwallet .modal-body {
}

#connectwallet .bluedarkbox {
  /* margin-top:0px !important; */
  background: #f3f3f3;
}

#connectwallet .contentbox {
  padding: 0px;
}

.swapbox .coinicon {
  top: -3px;
}

.cryptobg .input-group > span:last-child {
  margin-left: -12px !important;
  text-align: right;
}

.settingmodal .tolerhight li {
  width: auto;
}

.settingmodal .tolerhight {
  margin-bottom: 29px;
}

.settingmodal .inputsearch > a:hover {
  background-color: transparent !important;
}

.settingmodal .inputsearch {
  margin-top: 10px;
}

#connectwallet .modal-title {
}

.stakedlppage .stackcardbox .buytradedata table {
  background: none;
  border-top: 1px solid #e6e6e6;
}

.liquiditypage .swapbox .convertbox,
.buytokenpage .swapbox .convertbox {
  font-size: 16px;
}

.liquiditypage .swapbox .form-control,
.buytokenpage .swapbox .form-control {
  font-size: 16px;
}

.liquiditypage .swapbox .pull-right,
.buytokenpage .swapbox .pull-right {
  font-size: 16px;
  text-align: right !important;
  float: right;
  width: auto;
}

.liquiditypage .swapbox .pull-right {
  font-weight: 400;
}

.stakedlppage .stackcardbox .panelcontentbox {
  padding: 0px 15px;
}

.stakedlppage .stackcardbox tr {
  padding-left: 0px;
  padding-right: 0px;
}

.webhide {
  display: none;
}

.staketablebox {
  display: table;
  width: 100%;
}

.staketablebox > div {
  display: table-cell;
  vertical-align: top;
  float: none;
  border-bottom: 1px dashed #fff !important;
  padding: 7px 11px;
  color: #121212;
  font-size: 16px;
  font-weight: 400;
  white-space: nowrap;
}
.staketablebox > div:first-child {
  padding-left: 0px;
}
.staketablebox > div:last-child {
  padding-right: 0px;
}
.footerbottom .accordion {
  display: flex;
}

.footerbottom .accordion > div {
  width: 100%;
  padding: 0px 5px;
}

.footerbottom .accordion-item .accordion-button,
.footerbottom .accordion-item {
  background: none;
  border: 0px;
  color: #fff;
  padding: 0px;
  box-shadow: none;
}

.footerbottom .accordion-button::after {
  display: none;
}

.footerbottom .accordion-body {
  padding: 0px;
}

.footerbottom .accordion-collapse {
  display: block;
}

.stackcardbox .moredetailsicon,
.stackcardbox .moredetailsicon td {
  text-align: right !important;
  width: 140px !important;
}

.visibilityhidden {
  visibility: hidden;
}

.stakedlppage .stackcardbox .cellbutton td {
  padding: 0px !important;
}

.stakedlppage #inactive .stackcardbox tbody > tr:first-child,
.stakedlppage #active .stackcardbox tbody > tr:first-child,
.stakedlppage #staked .stackcardbox tbody > tr:first-child {
  width: 250px;
}

.stakedlppage .stackcardbox .linkbutton td {
  padding: 3px 4px !important;
}

.stakedlppage .webhide {
  display: none;
}

.connecttable {
  display: table;
  width: 100%;
  padding: 6px 4px;
}

.connecttable > div {
  display: table-cell;
  vertical-align: top;
  width: 50%;
  font-size: 16px;
}

.connecttable > div:last-child {
  text-align: right;
  width: 13%;
}

.profiledrop .dropdown-menu {
  min-width: 300px;
  padding: 12px;
  padding-top: 25px;
}

.profiledrop .profileicon {
  width: 60px;
  height: 60px;
  border: 3px solid #f3f3f3;
  background: #f3f3f3;
  display: block;
  border-radius: 50px;
  text-align: center;
  margin: 0px auto;
  margin-bottom: 21px;
  padding: 5px;
}

.profiledrop p {
  text-align: center;
}

.profiledrop p > span {
  background: none;
  padding: 7px 12px !important;
  border-radius: 5px;
  border: 1px solid #e4e4e4;
  font-size: 15px;
}

.walletdrop .tabbanner {
  border-bottom: 1px solid #3d85c6;
  display: flex;
  flex-wrap: inherit;
  margin-bottom: 10px;
}

.walletdrop .tabbanner li {
  width: 100%;
  text-align: center;
  padding: 0px;
}

.walletdrop .tabbanner li.nav-item a.active {
  background: none !important;
  border-bottom: 2px solid #3d85c6 !important;
  color: #fff !important;
}

.walletdrop .tabbanner li.nav-item a {
  border-radius: 0px !important;
  color: #fff !important;
}

.walletdrop .tabbanner li.nav-item a:hover,
.walletdrop .tabbanner li.nav-item a:focus,
.walletdrop .tabbanner li.nav-item a:active {
  background: none !important;
  color: #fff;
}

.walletdrop .btn {
  width: 100%;
  color: #fff !important;
}

#trans .connecttable > div {
  font-size: 12px;
}

.walletdrop .alink {
  color: #b7efb4 !important;
  font-size: 13px !important;
  font-weight: 400 !important;
}

.connecttable > div small {
  font-size: 14px;
  color: #b1b1b1;
}

.connecttable .coinicon {
  width: 22px;
}

.transhead > div {
  font-weight: 600;
}

.connecttable img {
  width: 18px;
}

.profiledrop .activedrop {
  xdisplay: block;
}

.common-loading {
  opacity: 0.5;
}

.liquiditymodalb .cryptobg .form-control {
  text-align: left;
  width: auto;
}

.liquiditymodalb .cryptobg .input-group > span:last-child {
  margin: 0px !important;
  padding-left: 20px !important;
  text-align: right;
  width: auto;
  font-size: 14px !important;
}

.smalltable tr td {
  padding: 9px 3px !important;
  font-size: 13px !important;
}

.smalltable table thead tr th {
  padding: 10px 10px !important;
  font-size: 12px !important;
  border-bottom: 1px solid #232549 !important;
  border-top: 1px solid #232549 !important;
  background: none;
}

.smalltable tbody > tr {
  border-left: 0px !important;
}

.smalltable tr:hover {
  background: none !important;
  border-left: 0px !important;
}

.smalltable .coinicon {
  width: 26px;
}

.smalltable .btn {
  padding: 4px 9px;
  font-weight: 500;
  text-transform: capitalize;
}

.smalltable .btn,
.smalltable .btn:hover,
.smalltable .btn:focus,
.smalltable .btn:active {
  background-color: #f9595924 !important;
  border: 1px solid #f95959 !important;
}

.smalltable {
  max-height: 319px;
}

.smalltable table {
  margin-bottom: 0px;
}

.liquiditymodalb .blancetext {
  margin-bottom: 12px;
}

.liquiditymodalb .btn {
  width: 100%;
}

.token-btn {
  padding: 7px 16px !important;
  border: 1px solid #fff;
  border-radius: 10px;
}

.gridparentbox {
  position: relative;
}

.modal-dialog {
  max-width: 630px;
}

.headbg .coinicon {
  width: 26px;
}

.coinselectbox .dropdown-bs-toggle {
  border: 1px solid #ff00c7;
  border-radius: 5px;
  background: none !important;
}

.stakedtab {
  border: 0px;
}

.headbg .navbar-nav.ms-auto .profiledrop .dropdown-menu {
  left: inherit;
  right: 0px;
}

.headbg .navbar-nav .profiledrop a.dropdown-bs-toggle:hover,
.headbg .navbar-nav .profiledrop a.dropdown-bs-toggle:focus,
.headbg .navbar-nav .profiledrop a.dropdown-bs-toggle:active {
  color: #fff !important;
}

.headbg .dropdown-menu .tabbanner li,
.walletdrop .tabbanner li.nav-item a {
  background: #fff !important;
}

.headbg .navbar-nav .coinselectbox a.dropdown-bs-toggle:hover,
.headbg .navbar-nav .coinselectbox a.dropdown-bs-toggle:focus,
.headbg .navbar-nav .coinselectbox a.dropdown-bs-toggle:active {
  color: #121212 !important;
}

.topbanner {
  padding: 0px 15px;
  border-radius: 0px;
  border-left: 0px;
  border-top: 0px;
  border-right: 0px;
  background: none;
  z-index: 99;
}

.topbanner .tabbanner {
  justify-content: center;
  margin: 0px;
  position: absolute;
  right: 0;
  top: 0;
}
.topbanner .container {
  position: relative;
}
.topbanner .tabbanner li.nav-item {
  padding: 0px 3px;
}

.topbanner .tabbanner li.nav-item a {
  background: #fff !important;
  font-size: 15px !important;
  padding: 8px 24px !important;
  border-radius: 7px !important;
}

.topbanner .tabbanner li.nav-item a.active {
  color: #ffff !important;
  background: #3f023b !important;
}

.swaptable .contentbox,
.liquiditytable .contentbox {
  padding: 15px 15px;
}

.historytab {
  margin-top: 2px;
}

.subscibe {
  width: 100%;
  max-width: 489px;
  float: right;
}

.walletboxicon {
  width: 100%;
  max-width: 74px;
  margin-bottom: 30px;
  opacity: 0.1;
}

.nodataicon {
  width: 100%;
  max-width: 143px;
  margin-bottom: 30px;
}

.balancetable {
  max-height: 255px;
}

.walletdrop h5 {
  text-align: center;
  color: #52a6f3;
  font-weight: 500;
  font-size: 19px;
  border-bottom: 1px dashed #e4e4e4;
  padding-bottom: 11px;
  padding-top: 4px;
  text-transform: capitalize;
}

.tokenlistbg .token-applybg .form-group label {
  margin-bottom: 8px;
}

.tokenlistbg .token-applybg .form-group {
  margin-bottom: 20px;
}

.token-applybg .panelcontentbox {
  width: 100%;
  max-width: 650px;
  margin: 0 auto;
}
.moreicon img,
.stackpage table tbody > tr > td:nth-child(2) img.ms-2 {
}

/*User css end*/

/* Home-css-start */
.features,
.viewbg,
.mobileappbg,
.faq-bg {
  background: #fff;
}
.headbg.homeheader {
  background: #fff;
  border: 0;
  padding: 16px 0;
  position: relative;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 999;
}

.headbg .navbar-nav li a.nav-link.sitebtn {
  color: #fff !important;
  padding: 8px 17px !important;
  border-radius: 5px;
}
.homebannerbg {
  position: relative;
  height: 86vh;
  padding: 0 !important;
  background-image: url(../images/banner-bg.svg);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  background-color: #fff;
}

.sitebannercontent {
  padding-top: 8vh;
  padding-bottom: 8vh;
}

.homeheader .sitebtn:hover {
  color: #fff !important;
}

.wallet-frm {
  padding: 32px 30px;
  background: #ffffffab;
  backdrop-filter: blur(8px);
  border-radius: 20px;
  width: 100%;
  max-width: 589px;
  margin: 0 auto;
  border: 1px solid #ffffff47;
  position: relative;
  z-index: 9;
}
.wlltfrm-circlebg {
  position: relative;
  width: 100%;
  max-width: 627px;
  margin: 0 auto;
  padding: 13px 13px;
}
.wlltfrm-circlebg::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-image: url(../images/form-circles.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top center;
}
.wlltfrm-circlebg::after {
  content: "";
  position: absolute;
  left: 0px;
  bottom: -122px;
  width: 100%;
  height: 188px;
  background-image: url(../images/shadowbox.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
}

.wallet-frm .cryptobg {
  border-color: #fff;
  background-color: #fff;
}

.wallet-frm .form-control {
  text-align: left;
  padding-right: inherit;
  padding-left: 0;
}

.wallet-frm .input-group-text {
  justify-content: flex-end;
}

.wallet-frm .siteformbox label {
  font-size: 20px;
  margin: 0 !important;
}

.wallet-frm .cryptobg .input-group > span > span {
  font-size: 20px;
}

.wallet-frm .cryptobg .input-group-text i img {
  max-width: 14px;
}

.wallet-frm .convertbox {
  padding: 10px 0px;
}

.wallet-frm .h4 {
  font-size: 30px;
  font-weight: 600;
  line-height: 30px;
  padding-bottom: 20px;
  text-align: center;
}

.c-btn .sitebtn {
  width: 100%;
  padding: 14px 25px;
  font-size: 23px;
}

.homebannerbg .h3 {
  font-size: 50px;
  font-weight: 600;
  line-height: 60px;
  color: #fff;
  padding-bottom: 12px;
}

.homebannerbg .h5 {
  font-size: 26px;
  font-weight: 400;
  line-height: 40px;
  color: #3f023b;
  padding-bottom: 15px;
}

.price-banner {
  display: flex;
  margin-top: 60px;
  width: 100%;
  max-width: 920px;
}

.price-banner .price-box {
  width: 100%;
}

.price-banner .price-box .h6 {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  color: #3f023b;
  margin: 0;
  text-transform: uppercase;
  text-align: left;
}

.price-banner .price-box .h5 {
  font-size: 22px;
  font-weight: 600;
  line-height: 31px;
  color: #3f023b;
  margin: 0;
  text-align: left;
  padding: 0;
}

.price-banner .price-box .coin-price {
  width: 40px;
  margin-right: 10px;
}

.price-banner .price-box .h6 span {
  margin-left: 20px;
}

.home .heading-title {
  font-size: 42px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  padding-bottom: 20px;
}
.features .heading-title {
  font-size: 36px;
  line-height: 49px;
  text-align: left;
  width: 100%;
  max-width: 922px;
}
.f-flex {
  margin-top: 35px;
}
.f-flex > div {
  width: 100%;
  max-width: 560px;
}
.f-flex > div:nth-child(even) {
  margin: 0px auto;
}
.f-flex > div:last-child {
  margin-left: auto;
}
.f-box .h5 {
  font-size: 26px;
  font-weight: 600;
  line-height: 30px;
}
.f-box .content {
  line-height: 27px;
}
.f-box {
  display: table;
  width: 100%;
}
.f-box > div {
  display: table-cell;
  vertical-align: top;
  float: none;
}
.f-box > div:first-child {
  width: 130px;
  position: relative;
}
.f-box > div:first-child::before {
  content: "";
  position: absolute;
  top: -49px;
  left: 49px;
  background: linear-gradient(179deg, #f0f3fb, #7780a0, #f0f3fb);
  width: 2px;
  height: 167%;
}
.ficonbg {
  width: 100px;
  height: 100px;
  background: #7780a0;
  border-radius: 50px;
  text-align: center;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  position: relative;
}
.home .content {
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  margin: 0;
  color: #787878;
}

.v-flex > div {
  width: 100%;
  padding-bottom: 47px;
}

.v-flex > div:last-child {
  padding-bottom: 0px;
}
.v-box .h4 {
  font-size: 37px;
  font-weight: 600;
  line-height: 37px;
  margin: 0;
  color: #ff00c4;
}

.v-box .h6 {
  font-size: 26px;
  font-weight: 500;
  line-height: 40px;
  margin: 0;
}

.aboutboxbg {
  background-image: url(../images/aboutbg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 45px 45px;
  border-radius: 15px;
  min-height: 504px;
  display: table;
  width: 100%;
}
.aboutboxbg > div {
  display: table-cell;
  vertical-align: middle;
  text-align: right;
}
.aboutboxbg .heading-title {
  color: #fff;
  text-align: right;
}
.aboutboxbg .content {
  color: #cecece;
  width: 100%;
  max-width: 500px;
  margin-left: auto;
}
.home section {
  padding: 60px 0px;
}

.f-box .f-icon {
  width: 100%;
  max-width: 37px;
}

.f-box {
  padding: 45px 30px;
}

.viewbg {
  position: relative;
}

.how-bg {
  background: #fff1f9;
}

.how-bg .heading-title {
  text-align: left;
}

.h-box .h6 {
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  margin: 0;
  padding-bottom: 5px;
}
.h-content > div {
  margin: 7px;
}
.h-box {
  padding: 30px 35px;
  display: table;
  width: 100%;
  background: #fff;
  border-radius: 12px;
  transition: all 0.4s ease-in-out;
}
.h-box:hover {
  box-shadow: -1px 10px 38px -2px #3b02334d;
}
.h-box > div {
  display: table-cell;
  vertical-align: middle;
}
.h-box > div:first-child {
  width: 100px;
}
.add-form {
  padding: 32px 30px;
  background: #d5d5d56b;
  backdrop-filter: blur(45px);
  border-radius: 20px;
  width: 100%;
  max-width: 482px;
  border: 1px solid #ffffff47;
  margin: 0 auto;
  position: absolute;
  top: 19%;
  margin-left: 55px;
}

.add-form .cryptobg .input-group-text i img {
  max-width: 11px;
}

.how-bg {
  position: relative;
}

.add-form .h4 {
  font-size: 35px;
  font-weight: 600;
  line-height: 30px;
  color: #fff;
  padding-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
}

.how-caret {
  width: 100%;
  max-width: 549px;
}

.how-bg .row > div:first-child {
  text-align: center;
}

.add-form .siteformbox label {
  margin: 0 !important;
}

.add-form .cryptobg .input-group > span > span {
  font-size: 18px;
}

.add-form .siteformbox label {
  font-size: 18px;
}

.appbtn img {
  width: 100%;
  max-width: 135px;
}
.mobileappicon img {
  width: 100%;
  max-width: 630px;
}
.mobileappbg {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}
.infoadsbanner {
  background: linear-gradient(45deg, #ff00c7, #ff9ffb);
  padding: 40px 0px !important;
}
.infoadsbg .heading-title {
  color: #fff;
}
.infoadsbg .content {
  color: #efefef !important;
  margin: 0px;
  font-size: 23px;
}
.aboutboxbg .heading-title {
  padding-bottom: 5px;
}

.aboutbg .h6 {
  font-size: 22px;
  font-weight: 400;
  line-height: 35px;
}

.aboutbg {
  position: relative;
  padding: 250px 0px !important;
}

.aboutbg::before {
  content: "";
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  max-width: 807px;
  height: 100%;
}

.accordion-item {
  border: 0;
  margin-bottom: 15px;
  background: #fceefb;
  xbox-shadow: 0px 0px 12px 7px #00000012;
  border-radius: 10px !important;
}

.accordion-button {
  background: #fceefb;
  transition: none;
  padding: 20px 20px;
  padding-right: 60px !important;
  box-shadow: none !important;
  border-radius: 7px;
}

.accordion-button:not(.collapsed) {
  background: transparent;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button::after {
  position: absolute;
  right: 23px;
}

.accordion-body {
  padding-top: 0 !important;
  padding: 10px 25px;
}

.accordion-button:not(.collapsed) h4 {
  color: #121212;
  font-weight: 600;
}

.faq-bg h4 {
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  margin: 0;
  width: 94%;
}

.accordion-button::after {
  background-image: url(../images/plus.svg);
  background-size: 15px;
  background-position: center;
  padding: 10px;
}

.accordion-button:not(.collapsed)::after {
  background-image: url(../images/minus.svg);
}
.home {
  padding-bottom: 346px;
}
footer.homefooter {
  padding: 0px;
  height: 346px;
}
.homefooter .content {
  color: #bc8fba;
  font-size: 19px;
  line-height: 30px;
  padding-right: 26px;
}

.homefooter .footer-gray-bg {
  position: relative;
  padding-top: 50px;
  padding-bottom: 32px;
  background: #3f023b;
}

.home .table-content {
  display: table;
  width: 100%;
  margin: 0;
}

.home .table-content > div {
  display: table-cell;
  vertical-align: middle;
  float: none;
  width: 50%;
}

#particles-js {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9;
  pointer-events: none;
  color: #000;
}

.how-img {
  position: relative;
}

.owl-carousel,
.owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative;
}

.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item {
  padding: 0px 10px;
}

.owl-carousel .owl-item img {
  display: block;
  /* width: 100%; */
  margin: 0 auto;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel .owl-dot,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
  background: 0 0;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.owl-height {
  transition: height 0.5s ease-in-out;
}

.owl-nav button {
  position: absolute;
  top: 50%;
  background: 0 0 !important;
  color: #fff;
  margin: 0;
  transition: all 0.3s ease-in-out;
}

.owl-nav button.owl-prev {
  left: 0;
}

.owl-nav button.owl-next {
  right: 0;
}

.owl-dots {
  text-align: center;
  margin-top: 0;
}

.owl-dots button.owl-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  background: #aeaeb0;
  margin: 0 3px;
}

.owl-dots button.owl-dot.active {
  background-color: #000000;
}

.owl-dots button.owl-dot:focus {
  outline: 0;
}

.owl-nav button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: 0 0 !important;
}

.owl-nav span {
  display: none;
  font-size: 30px;
  color: #c7c7c7;
  font-weight: 700;
}

.owl-nav button:focus {
  outline: 0;
}

.owl-dots {
  display: none;
}

.owl-nav::before {
  xdisplay: none;
}

.owl-nav {
  position: absolute;
  top: 54%;
  width: 100%;
  text-align: left;
  color: #0c2664;
}

button.owl-next {
  right: 0;
  float: right;
}

.p-box img {
  /* width: 100%; */
  max-width: 100px;
}

.topbannerinnerpage {
  padding-top: 154px !important;
  padding-bottom: 0px !important;
}

.innerpage .headbg.homeheader {
  background-image: linear-gradient(45deg, #081d3d, #2f5397, #254581);
}
.homeheader .dropdown-menu {
  margin-top: 16px;
}
body.innerpage.home::before {
  display: block;
}

.home.innerpage section {
  padding: 50px 0px;
}

.navbar-toggler {
  transition: 0.5s ease-in-out;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
  border: 0;
  position: relative;
}

.navbar-toggler span {
  margin: 0px;
  padding: 0px;
}

.toggler-icon {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #121212;
  border-radius: 1px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

.middle-bar {
  margin-top: 0px;
}

.navbar-toggler.collapsed .top-bar {
  position: absolute;
  top: 0px;
  transform: rotate(0deg);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
  position: absolute;
  top: 10px;
  filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
  position: absolute;
  top: 20px;
  transform: rotate(0deg);
}

.navbar-toggler .top-bar {
  top: inherit;
  transform: rotate(135deg);
}

.navbar-toggler .middle-bar {
  opacity: 0;
  top: inherit;
  filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
  top: inherit;
  transform: rotate(-135deg);
}

.navbar-toggler.collapsed .toggler-icon {
  background: #000;
}

.darkHeader {
  backdrop-filter: blur(15px);
  background: #ffffff9e !important;
  box-shadow: 0 0 21px -3px rgb(210 210 210);
  position: fixed !important;
  padding: 10px 0px;
}

.headbg.homeheader.darkHeader .navbar-nav li a.nav-link {
  color: #0b2144 !important;
  border-color: #3d85c6 !important;
}

.headbg.homeheader.darkHeader .modeicon {
  border-color: #c6c6c6;
  background: #c6c6c6 !important;
}

.headbg.homeheader.darkHeader .navbar-nav li a.nav-link span {
  color: #fff !important;
}

.partnerdata {
  display: flex;
  width: 100%;
}

.partnerdata > div {
  width: 100%;
  text-align: center;
}

.logo.light {
  display: none;
}

.h-box .accordion-button:not(.collapsed)::after {
  background-image: none;
  border: 0;
}
.h-box img {
  width: 100%;
  max-width: 50px;
}
.h-box .accordion-button {
  padding-left: 0 !important;
  padding: 0px 0px;
  background: transparent;
}

.h-box .accordion-button:hover {
  background: transparent;
}

.h-box .accordion-button::after {
  background-image: none;
  border: 0;
}

.h-box .accordion-body {
  padding: 0;
}
.h-box .content {
  line-height: 30px;
}
.h-table {
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  z-index: 9;
}

.h-table > div {
  width: 100%;
}

.h-table > div:first-child {
  width: 100px;
}

.h-point {
  width: 60px;
  height: 60px;
  background: #eef3ff;
  border-radius: 50px;
  display: flex;
  align-items: center;
}

.h-inner {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  margin: 0 auto;
  justify-content: center;
}

.h-active {
  background: #3d85c6;
}

.h-box .accordion-button .h-inner {
  background: #3d85c6;
}

.h-box .accordion-button.collapsed .h-inner {
  background: #fff;
}

.h-box .accordion-item {
  padding: 20px 0px;
}

.h-content {
  position: relative;
}

/* Home-css-end */
/* Custom Alert*/
.alert-org-color {
  color: inherit;
}

.c-btn .nav-item,
.homeopt .nav-item {
  list-style-type: none;
}
/*privacy css start*/
.privacycontentpage p {
  font-size: 18px;
  line-height: 32px;
  color: #787878;
}
.privacycontentpage h4 {
  font-size: 23px;
  padding-top: 10px;
  font-weight: 600;
}
.contentpages {
  padding-top: 50px;
  padding-bottom: 50px;
}
/*privacy css end*/
/* faq-page */
.faqpage .accordion-button {
  padding: 15px 20px;
}
.faqpage .content {
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  margin: 0;
}
.faqpage .accordion-item .accordion-button h4 {
  font-size: 23px;
  line-height: 30px;
  margin: 0;
  font-weight: 500;
}

.nodata {
  width: 100%;
  /* display: inline-block; */
  text-align: center;
}
.nodata span {
  display: block;
  font-size: 18px;
  font-weight: 500;
}
.no-record {
  width: 100%;
  max-width: 131px;
  margin-bottom: 11px;
}
.nodata > td {
  padding: 50px 0px !important;
}

.staketablebox > div.nodata {
  border-bottom: 0 !important;
}
.marketsearch .d-flex > div {
  width: 100%;
  margin: 5px 5px;
}
.marketsearch .form-control {
  appearance: auto;
}
.marketsearch {
  width: 100%;
  max-width: 450px;
  top: 0px;
  right: 0px;
}
.marketpage {
  margin-top: 10px;
  width: 100%;
}
.contactbg {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}
.contactbg textarea.form-control {
  height: auto;
}
.cm-logo {
  width: 100%;
  max-width: 573px;
}
.comingsoonbg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.cs {
  display: flex;
  width: 100%;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translate(-50%, 0);
}
.headbg .navbar-brand {
  width: 133px;
}
.logo:focus-visible {
  outline: none;
}
.marketpage table tbody tr:hover {
  background-color: #fcf3fc;
}
.marketpage table tbody tr th,
.marketpage table tbody tr td {
  padding: 11px 14px !important;
}
.list-hind {
  display: block;
  line-height: 12px;
  font-size: 15px;
  font-weight: 600;
  color: #3b6d99;
}

.sitetable thead {
  position: relative;
  z-index: 9;
}
.t-doller {
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  margin: 0;
  display: block;
}

.stackpage .sitetable .nodata td,
.stackcardbox table .nodata td {
  text-align: center !important;
}

a.navbar-brand {
  outline: none;
}
.homebannerbg .convertbox {
  text-align: end;
}

.custom-menu-li-style {
  list-style-type: none;
}
.stackpage .stakeicon > div span {
  color: #b1b1b1;
  font-size: 15px;
  line-height: 20px;
}

.apyicon {
  position: relative;
  top: -2px;
}
.marketpage .contentbox {
  padding: 20px 30px;
}

.overlay.active {
  background: #040506e0;
  position: fixed;
  width: 100%;
  left: 0px;
  top: 0px;
  height: 100%;
  z-index: 9999;
}
.importpoolbox .input-group-text .convertbox {
  padding-left: 0px !important;
}
.mobileappbg .content {
  padding-bottom: 10px;
}
/* homepage-livepricedata */
.charts {
  width: 100%;
  min-height: 50px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  text-align: center;
}

.pricelistbg {
  padding: 0 !important;
  position: relative;
  width: 100%;
  overflow: hidden;
}
.pricelistbg .container {
  width: 100%;
  max-width: 100%;
}

.charts.myUl {
  position: relative;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.charts.myUl li {
  float: left;
  margin: 0px;
  padding: 10px 0px;
  text-align: left;
}

.live-table {
  display: table;
  float: left;
  margin: 0px 4px;
  padding: 14px 10px;
  border-radius: 15px;
  text-align: left;
}
.live-table > div {
  display: table-cell;
  vertical-align: middle;
}
.live-table > div:last-child .h2 {
  text-align: right;
  font-size: 18px;
  font-weight: 600;
  padding-left: 25px;
}
.live-table > div:last-child .h4 {
  text-align: right;
}
.live-table .h2 {
  margin: 0px;
  font-size: 20px;
  padding-left: 10px;
  font-weight: 500;
  line-height: 25px;
  /* text-transform: uppercase; */
  color: #000000;
}

.live-table .h4 {
  margin: 0px;
  font-size: 14px;
  padding: 0px;
  padding-left: 10px;
  font-weight: 500;
  line-height: 20px;
  color: #000000;
}
.charts li {
  list-style: none;
  float: left;
  padding: 0px 7px !important;
}
.livecoinig {
  width: 30px;
}

.live-table img {
  width: 40px;
  height: 40px;
  vertical-align: inherit;
}
.myUl {
  animation: endlessRiver 10s linear infinite;
  white-space: nowrap;
}

@keyframes endlessRiver {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.tickercontainer {
  width: 100%;
  min-height: 50px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  text-align: center;
}

.tickercontainer .mask {
  position: relative;
  width: 100%;
  overflow: hidden;
}

ul.newsticker {
  position: relative;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

ul.newsticker li {
  float: left;
  margin: 0px;
  padding: 10px 0px;
  text-align: left;
}

ul.er-controls {
  list-style: none;
  display: inline-block;
  padding: 0;
}

ul.er-controls > li {
  display: inline-block;
  padding: 5px 10px;
  margin: 0px;
  border-radius: 0px;
  color: #333;
  font-size: 13px;
}

ul.er-controls > li:hover {
  background: none;
  cursor: pointer;
}
