@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .homebannerbg {
    height: auto;
  }

  .sitebannercontent {
    padding-top: 5vh !important;
    padding-bottom: 2vh;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .homebannerbg {
    height: auto;
  }

  .sitebannercontent {
    padding-top: 5vh !important;
    padding-bottom: 14vh;
  }
}

@media only screen and (device-width: 768px) {
  .homebannerbg {
    height: auto;
  }

  .sitebannercontent {
    padding-top: 5vh !important;
    padding-bottom: 14vh;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
  .homebannerbg {
    height: auto;
  }

  .sitebannercontent {
    padding-top: 5vh !important;
    padding-bottom: 2vh !important;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  .homebannerbg {
    height: auto;
  }

  .sitebannercontent {
    padding-top: 5vh !important;
    padding-bottom: 9vh;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 500px) and (orientation: landscape) {
  .comingsoonbg {
    position: relative;
    left: inherit;
    top: inherit;
    transform: inherit;
    text-align: center;
  }
  .cs {
    position: relative;
    left: inherit;
    right: inherit;
    transform: inherit;
  }
  .cm-logo {
    padding-top: 27vh !important;
    padding-bottom: 15vh !important;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 659px) and (orientation: portrait) {
  .comingsoonbg {
    position: relative;
    left: inherit;
    top: inherit;
    transform: inherit;
    text-align: center;
  }
  .cs {
    position: relative;
    left: inherit;
    right: inherit;
    transform: inherit;
  }
  .cm-logo {
    padding-top: 27vh !important;
    padding-bottom: 15vh !important;
  }
}

@media only screen and (max-height: 660px) {
  .cm-logo {
    padding-top: 11vh;
    padding-bottom: 7vh;
  }
  .comingsoonbg {
    position: relative;
    left: inherit;
    top: inherit;
    transform: inherit;
    text-align: center;
  }
  .cs {
    position: relative;
    left: inherit;
    right: inherit;
    transform: inherit;
  }
}
@media only screen and (max-height: 500px) {
  .cm-logo {
    padding-top: 30vh;
    padding-bottom: 14vh;
  }
}

@media (max-width: 1700px) {

  .homefooter .partcal-img.bottom {
    right: 0;
  }

  .aboutbg::before {
    max-width: 743px;
  }

  .aboutbg {
    padding: 215px 0px !important;
  }
  .homebannerbg .h3 {
    font-size: 44px;
    line-height: 55px;
  }
}

@media (max-width: 1600px) 
{
  .aboutbg {
    padding: 176px 0px !important;
  }
  .aboutbg::before {
    max-width: 660px;
  }
}

@media (max-width: 1400px) {
  .walletpage .flexbox > div:first-child {
    width: 60%;
  }

  .stakedlppage .stackpage table tbody .databottomrow > td:last-child {
    font-size: 15px;
  }

  .buytradedata .alink {
    font-size: 9px;
  }

  .liquiditypage .swapbox .input-group-text > div:first-child {
    width: 137px;
  }
  .livepricetable img {
    max-width: 53px;
  }
  
  .how-caret {
    max-width: 498px;
  }

  .add-form {
    margin-left: 39px;
    top: 15%;
  }
  .home .heading-title {
    font-size: 37px;
  }
 
}

@media (max-width: 1300px) {
  .buytradedata .alink {
    font-size: 8px;
  }

  .swapexchange .convertbox {
    min-width: 122px !important;
  }

  .buytokenpage .swapbox .form-control {
    font-size: 26px !important;
  }

  .aboutbg::before {
    max-width: 580px;
  }

  .aboutbg {
    padding: 130px 0px !important;
  }

}

@media (max-width: 1250px) {
  .stackpage > div {
    width: 33.3%;
    max-width: 33.3%;
    flex: 33.3%;
  }

  .dashboardbanner::before {
    background-size: 50%;
  }

  .staketablebox > div:last-child {
    width: 100px;
  }

  .staketablebox > div {
    padding: 13px 7px;
  }

  .stackcardbox .moredetailsicon,
  .stackcardbox .moredetailsicon td {
    width: 120px !important;
  }
  .gridparentbox,
  footer, .userheader {
    padding-left: 153px;
    padding-right: 16px;
  }
  .livepricetable > div:last-child {
    width: 60px;
  }
  .topbanner .tabbanner
  {
    right:10px;
  }
}

@media (max-width: 1200px) {
  .cs {
    top: 33%;
  }
  .sitecontainer,
  .userheader .container,
  .topbannerbg .container,
  .userfooter .container {
    max-width: 100%;
    padding: 0px 15px;
  }

  .stackcardbox tr.cellbutton {
    width: 145px;
  }

  .innerpagecontent h3 {
    font-size: 25px;
  }

  .cryptobg .form-control {
    font-size: 25px !important;
  }

  .buytradedata .alink {
    font-size: 11px;
  }

  .headbg .navbar-nav li a.nav-link {
    padding: 12px 14px;
    font-size: 17px;
  }
  .livepricetable img 
  {
    max-width: 50px;
  }
  .contentpages .container
  {
    width:100%;
    max-width: 100%;
  }
  .sitebannercontent {
    padding-top: 7vh;
  }

  .headbg.homeheader .container,
  .homebannerbg .container,
  .features .container,
  .viewbg .container,
  .how-bg .container,
  .faq-bg .container,
  .homefooter .container, .mobileappbg .container, .infoadsbanner .container {
    width: 100%;
    max-width: 100%;
  }

  .homebannerbg .h3 {
    font-size: 39px;
    line-height: 53px;
  }
 
  .f-box {
    padding: 45px 23px;
  }

  .v-box .h4 {
    font-size: 32px;
  }

  .v-box .h6 {
    font-size: 24px;
    line-height: 36px;
    margin: 0;
  }

  .add-form {
    padding: 20px;
    max-width: 436px;
    margin-left: 33px;
    top: 14%;
  }

  .f-box .h5,
  .h-box .h6 {
    font-size: 25px;
  }


  .faq-bg h4 {
    font-size: 20px;
  }
 
}

@media (max-width: 1150px) {
  .swapexchange .flexbox {
    display: block;
    width: 100%;
  }

  .swapexchange .flexbox > div {
    display: block;
    width: 100%;
    margin: 10px 0px;
  }

  .supportsearch {
    width: 100%;
    max-width: 150px;
  }

  .stakedtab .supportsearch {
    max-width: inherit;
  }

  .liquiditypage .swapbox {
    border-right: 0px;
    padding-right: 0px;
  }

  .liquidtybalance {
    display: flex !important;
  }

  .liquiditypage .swapbox .tabrightbox {
    right: 7px;
  }

  .swapexchange .swapbox .blancetext .pull-right {
    text-align: center !important;
    float: none !important;
  }

  .staketablebox > div {
    padding: 13px 3px;
  }
 
}

@media (max-width:1100px)
{
  .add-form .cryptobg {
    padding: 10px 15px;
  }
}

@media (max-width: 1080px) {
  .partcal-img.bottom {
    right: 21px;
  }
  .partner .partcal-img.bottom {
    right: 12px;
  }
  .flexbox {
    display: block;
    width: 100%;
  }

  .flexbox > div {
    display: block;
    width: 100% !important;
    margin: 10px 0px;
  }

  .dashboardbanner::before {
    background-size: 80%;
  }
  .f-box .h5, .h-box .h6 {
    font-size: 22px;
    line-height: 27px;
  }
  .features .heading-title {
    font-size: 30px !important;
    line-height: 42px;
  }
}


@media (max-width: 1050px) {
  .partnerdata > div {
    width: 33%;
    text-align: center;
    margin: 13px 0px;
  }

  .partnerdata {
    flex-wrap: wrap;
  }

  .homebannerbg .h3 {
    font-size: 40px;
    line-height: 50px;
    padding-right: 15px;
  }

  .wallet-frm {
    padding: 25px;
  }

  .wallet-frm .h4 {
    padding-bottom: 10px;
    font-size: 30px;
  }

  .wallet-frm .form-group.cryptobg {
    padding: 10px 15px;
  }

  .wallet-frm .cryptobg .input-group > span > span {
    font-size: 18px;
  }

  .wallet-frm .siteformbox label {
    font-size: 18px;
  }

  .c-btn .sitebtn {
    margin-top: 5px;
  }

  .v-box .h4 {
    font-size: 35px;
  }

  .v-box .h6 {
    font-size: 20px;
    line-height: 30px;
  }

  .add-form .siteformbox .form-group {
    margin-bottom: 10px;
  }

  .add-form .h4 {
    font-size: 28px;
    padding-bottom: 10px;
  }

  .add-form .convertbox {
    padding: 10px 0px;
  }

  .how-caret {
    max-width: 467px;
  }

  .add-form {
    margin-left: 18px;
    top: 17%;
  }

  .home .heading-title {
    font-size: 37px;
  }

  .aboutbg::before {
    max-width: 465px;
  }

  .aboutbg {
    padding: 75px 0px !important;
  }

  .accordion-button {
    padding-bottom: 15px;
  }
}

@media (max-width: 1024px) {
  .stackpage > div {
    width: 50%;
    max-width: 50%;
    flex: 50%;
  }
}

@media (max-width: 991px) {
  .headbg .navbar-nav li .sitebtn {
    font-size: 15px;
    padding: 9px 17px !important;
  }
  .cs {
    top: 28%;
  }

  .modeicon {
    padding: 11px 11px !important;
  }

  .headbg .navbar-nav li a.nav-link {
    padding: 12px 7px;
    font-size: 15px;
  }

  .modeicon {
    margin-top: 2px !important;
  }

  .footer-gray-bg h3 {
    font-size: 20px;
  }

  .social-bg {
    margin-bottom: 19px;
  }

  .leftsidemenu {
    display: none;
  }

  .pagewrapperbox .leftsidemenu {
    display: block;
  }

  .leftsidescroll {
    margin-top: 0px;
  }


  .pagewrapperbox .gridparentbox,
  .pagewrapperbox .footerbox,
  .pagewrapperbox .headbg {
    padding-left: 0px !important;
  }

.leftsidemenu
{
  width:110px;
}

  .leftsidemenu .logo {
    display: none !important;
  }

  .mobiletoggle {
    display: inline-block;
    position: absolute;
    left: 5px;
    top: 23px;
    z-index: 9;
  }

 .userheader .navbar-brand .logo {
    margin-left: 16px;
}

  .coinleft {
    float: none;
  }

  .sitecontainer {
    padding: 0px 15px;
  }

  footer {
    /* height: 60px; */
  }

  body {
    /* padding-bottom: 60px; */
  }

  .ftext {
    padding-top: 20px;
  }

  .dashboardbanner::before {
    background-size: cover;
  }

  .profiledrop .dropdown-menu {
    margin-top: 25px;
  }

  .headbg {
    padding: 17px 0px;
  }

  .profiledrop a.dropdown-bs-toggle,
  li.coinselectbox a.dropdown-bs-toggle, .userheader li.custom-menu-li-style .btn 
  {
    padding: 7px 12px !important;
  }

  .livepricetable {
    padding: 15px 15px;
  }

  .livepricetable img {
    max-width: 33px;
  }

  .livepricetable .h3 {
    font-size: 19px;
  }
  .gridparentbox, footer, .userheader {
    padding-left: 15px;
    padding-right: 15px;
  }
  .headbg .coinicon {
    width: 19px;
  }
  .closeiconbtn {
		display: block;
	}
  .homebannerbg .h3 {
    font-size: 33px;
    line-height: 41px;
  }

  .homebannerbg .h5 {
    font-size: 21px;
    line-height: 33px;
    padding-bottom: 16px;
  }

  .wallet-frm .h4 {
    padding-bottom: 10px;
    font-size: 28px;
  }

  .c-btn .sitebtn {
    width: auto;
    padding: 12px 25px;
    font-size: 18px;
  }

  .sitebannercontent {
    padding-top: 7vh;
    padding-bottom: 7vh;
  }

  .f-flex > div {
    margin: 0px 0px;
    max-width: 100%;
    padding: 20px 0px;
  }

  .ficonbg
  {
    width: 70px;
    height: 70px;
  }
  .f-box .f-icon {
    max-width: 25px;
  }
  .f-box>div:first-child
  {
    width: 100px;
  }
  .f-box .h5 {
    font-size: 25px;
  }

  .home .content {
    font-size: 18px;
    line-height: 30px;
  }

  .v-flex > div {
    padding-bottom: 19px;
  }

  .h-box .h6 {
    font-size: 25px;
  }
  .h-box .h6 {
    font-size: 20px;
    line-height: 23px;
}
  .how-bg .table-content {
    display: flex;
    flex-flow: column;
  }

  .h-box>div:first-child {
    width: 65px;
}
.h-box img
{
  width:100%;
  max-width: 40px;
}

  .h-content>div {
    padding: 24px;
}

  .how-bg .table-content > div {
    display: block;
    width: 100%;
  }

  .how-bg .table-content > div:first-child {
    order: 2;
    margin-top: 20px;
  }

  .how-bg .table-content > div:last-child {
    order: 1;
  }

  .add-form {
    left: 22%;
  }

  .aboutbg::before {
    display: none;
  }

  .aboutbg .heading-title,
  .aboutbg {
    text-align: center;
  }

  .homefooter .foot-list {
    flex-wrap: wrap;
    margin-bottom: 25px;
  }

  .wallet-frm {
    padding: 15px;
  }

  .aboutbg .h6 {
    font-size: 19px;
    line-height: 33px;
  }
  .price-banner .price-box .h5 {
    font-size: 17px;
  }
  .price-banner .price-box .coin-price {
    width: 31px;
    margin-right: 7px;
  }
  .f-box>div:first-child::before
  {
    left: 41px;
    display: none;
  }
  .f-flex {
    margin-top: 0;
  }
  .viewbg .row>div:first-child
  {
    width:30%;
  }
  .viewbg .row>div:last-child
  {
    width:70%;
  }
  .aboutboxbg
  {
    min-height: inherit;
  }
  .infoadsbg .heading-title
  {
    font-size: 27px;
  }
  .homefooter .row>div
  {
    margin-bottom:15px;
  }
  .innerpagecontent h3 {
    font-size: 21px;
  }
}
@media (max-width: 850px) {
  .homebannerbg .h3 {
    font-size: 29px;
    line-height: 38px;
  }
}
@media screen and (max-width: 767px) {
  .c-btn .sitebtn {
    font-size: 15px !important;
  }
  .sitebtn,
  .border-btn {
    font-size: 16px;
  }
  .navbar-collapse {
    border-top: 1px solid #ffffff1f;
    margin-top: 11px;
  }

  .livepricetable {
    display: table;
    width: 100%;
  }

  .livepricetable > div {
    display: table-cell;
    vertical-align: middle;
    float: none;
  }

  .livepricetable > div:last-child {
    text-align: right;
  }

  .table-responsive-stack tr {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .table-responsive-stack td,
  .table-responsive-stack th {
    display: block;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    white-space: inherit !important;
    font-size: 14px;
  }

  .table-responsive-stack tr {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    border-bottom: 0;
    display: block;
  }

  .table-responsive-stack td {
    width: 100%;
    text-align: right;
    padding: 8px 10px !important;
    border-color: #cbe2fd !important;
    color: #ffffff !important;
    font-size: 13px !important;
  }

  .table-responsive-stack tbody > tr:nth-child(even) td {
    border-color: #e3edf9 !important;
  }

  .table-responsive-stack .table-responsive-stack-thead {
    float: left;
    text-align: left;
    padding-right: 10px;
    text-transform: capitalize;
    font-size: 13px;
    letter-spacing: 0px;
    color: #b6a2fb;
  }

  .table-responsive-stack tbody > tr:nth-child(odd) {
    background: #140d32;
  }

  .table-responsive-stack tbody > tr:nth-child(even) {
    background: #2a2155;
  }

  .tokentable table tbody > tr:nth-child(even) {
    background: none;
  }

  .tokentable table tr td,
  .tokentable table tr th {
    text-align: right;
  }

  .tokentable table tr .table-responsive-stack-thead {
    display: none !important;
  }

  .tokentable table tr td {
    width: 50%;
    display: table-cell;
    float: left;
    text-align: left;
    padding: 3px 0px !important;
  }

  .tokentable table tr > td:nth-child(even) {
    text-align: right;
  }

  .tokentable table tr {
    padding: 12px;
    display: inline-block;
    width: 100%;
    border-radius: 10px;
  }

  .table tbody tr {
    border-left: 0px !important;
  }

  .logo {
    max-width: 102px;
  }
  .headbg .navbar-brand
  {
    width:102px;
  }
  .homeheader .logo {
    margin-top: 4px;
  }
  .heading-box small {
    display: block;
    padding-right: 59px;
  }

  .footlistbg {
    padding-bottom: 20px;
  }

  .footer-gray-bg p {
    margin-bottom: 0px;
    padding-top: 0px;
    font-size: 15px;
  }

  .social-bg {
    margin-top: 0px;
    padding-bottom: 0px;
  }

  

  .headbg .navbar-nav li a.nav-link {
    padding: 9px 13px !important;
    font-size: 17px;
  }

  .profiledrop a.dropdown-bs-toggle,
  .coinselectbox a.dropdown-bs-toggle, .userheader .custom-menu-li-style .btn {
    width: 100%;
    max-width: 150px;
    text-align: center;
    margin-bottom: 10px;
  }


  .headbg .modeicon {
    margin-left: 0px;
    font-size: 16px;
  }

  .profiledrop a.dropdown-toggle img {
    max-width: 22px;
  }

  .iconbtn img {
    width: 18px;
    position: relative;
    top: -1px;
  }

  .headbg .iconbtn {
    margin-bottom: 5px;
  }

  .profiledrop .dropdown-toggle {
    width: 100%;
    max-width: 200px;
  }

  .headbg .container {
    padding: 0px;
  }

  .headbg,
  .pagewrapperbox .headbg {
    padding: 13px 16px !important;
    position: fixed;
  }

  .pagewrapperbox .headbg, .userheader
  {
    position: absolute;
  }

  .headbg .dropdown-menu 
  {   
    position: absolute;
    margin-top: -2px;
  }

  .homeheader.headbg .dropdown-menu {
    left: 0px !important;
    right: inherit !important;   
  }

  .innerpagecontent h3 {
    font-size: 25px;
    margin: 0px;
    padding: 0px !important;
  }

  .heading-box {
    font-size: 18px;
  }

  .stakedtab .tabrightbox {
    position: relative;
    right: inherit;
    top: inherit;
    width: 100%;
    justify-content: center;
  }

  .historytab .tabbanner {
    width: 100%;
    flex-wrap: inherit;
  }

  .historytab .tabbanner li {
    width: 100%;
    text-align: center;
  }

  .liquiditybox {
    text-align: left;
  }

  .liquiditybox .btn {
    min-height: 58px;
    min-width: 155px;
    padding: 17px 10px;
  }

  .supportsearch {
    max-width: 100%;
  }

  .foot-list {
    padding-bottom: 0px;
  }

  

  .leftsidemenu li {
    padding-bottom: 4px;
  }

  .gridparentbox {
    padding-top: 117px;
  }

  .dashboardbanner::after {
    display: none;
  }

  .stackcardbox table tr td {
    width: 100%;
    white-space: inherit !important;
    display: inline-block !important;
    padding: 0px 5px !important;
    line-height: 18px !important;
  }

  .stackcardbox tr {
    display: inline-block !important;
    width: 50% !important;
    padding: 6px 11px;
  }

  .stackcardbox table tbody > tr:nth-child(odd) td {
    text-align: right !important;
  }

  .stackcardbox tr.moredetailsicon {
    width: 100% !important;
  }

  .stackcardbox table tr.databottomrow,
  .stackcardbox table tbody > tr:first-child {
    width: 100% !important;
    text-align: left !important;
  }

  .stackcardbox table tr.databottomrow td,
  .stackcardbox table tbody > tr:first-child td {
    text-align: left !important;
  }

  .stackcardbox .buytradedata table tbody > tr:nth-child(odd) td {
    text-align: left !important;
  }

  .stackcardbox .buytradedata table tbody > tr:nth-child(even) td {
    text-align: right !important;
  }

  .stackcardbox .buytradedata table tbody > tr:last-child td,
  .stakedlppage .stackcardbox .buytradedata tr td {
    width: 50%;
    text-align: left !important;
    display: table-cell !important;
    padding: 7px 11px !important;
  }

  .stakedlppage .stackcardbox .buytradedata tr {
    width: 100% !important;
  }

  .stackcardbox .buytradedata table tbody > tr:last-child > td:last-child {
    text-align: right !important;
  }

  .stackcardbox tr.cellbutton {
    width: 100% !important;
  }

  .stackcardbox table .buytradedata {
    width: 100% !important;
    padding: 0px !important;
  }

  .stackcardbox table .buytradedata tr,
  .stackcardbox table .buytradedata table tbody > tr:first-child {
    /* width: 33.3% !important; */
  }

  .stackcardbox .buytradedata > td {
    padding: 0px !important;
  }

  .stackcardbox .buytradedata table .cellbutton td,
  .stackcardbox .buytradedata table .cellbutton {
    width: 100% !important;
  }

  .innerpagecontent {
  }

  .pricerowbox {
    margin: 0px;
  }

  .innerpagecontent::before,
  .innerpagecontent::after {
    display: none;
  }

  .profiledrop .dropdown-toggle > span {
    display: none;
  }

  .profiledrop .dropdown-toggle {
    border: 0px !important;
  }



  .headbg .navbar-nav {
  }

  .priceflexbox {
    display: inline-block;
    width: 100%;
  }

  .priceflexbox > div {
    margin: 0px;
    margin-bottom: 10px;
  }

  .topcontentbox {
    max-width: 100%;
  }

  .livepricetable {
    padding: 15px;
  }

  .livepricetable .h3 {
    font-size: 18px;
    padding-top: 10px;
  }

  .livepricetable img {
    max-width: 37px;
  }

  .leftsidemenu li a {
    padding: 15px 13px;
  }

  .webhide {
    display: inline-block;
    width: 100%;
  }


  .mobilehide {
    display: none;
  }

  

  .leftsidescroll {
    height: 100%;
  }

  .swapxchange .paneldarkbox {
    background: none;
  }

  .balancetable.lightdark > div {
    padding: 8px 15px;
  }

  .balancetable {
    padding: 0;
  }

  .blancemobile.lightdark {
    background: #f3f3f3;
    margin-bottom: 0px;
  }

  .liquiditypage .swapbox .pull-right,
  .buytokenpage .swapbox .pull-right {
    font-size: 14px;
  }

  .buytradedata .alink {
    font-size: 12px;
  }

  .stakepanel .sitetable tbody tr td {
    display: inline-block;
    width: 50%;
    border-bottom: 0px !important;
    padding: 8px 4px;
  }

  .stakepanel .sitetable tbody > tr > td:nth-child(even) {
    text-align: right;
  }

  .stakepanel .sitetable tbody > tr > td:last-child {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #201747 !important;
  }

  .stackcardbox table .buytradedata tr,
  .stackcardbox table .buytradedata table tbody > tr:first-child {
    width: 50% !important;
  }

  .stackcardbox table .buytradedata table tbody > tr:last-child {
    width: 100% !important;
    display: table !important;
  }

  .stakedlppage .stackcardbox .buytradedata tr {
    width: 100% !important;
    display: table !important;
  }

  .stakedlppage .stackcardbox .panelcontentbox {
    padding: 0px 0px;
    padding-top: 12px;
  }

  .stakedlppage .stackcardbox .buytradedata table tr td {
    width: 50% !important;
  }

  .stakedlppage .stackcardbox .buytradedata table tbody > tr:first-child {
    width: 100% !important;
    display: table !important;
  }

  .stakedlppage .stackcardbox .buytradedata table tbody > tr:first-child td,
  .stakedlppage .stackcardbox .buytradedata table tbody > tr:last-child td {
    width: 50% !important;
    display: table-cell !important;
  }

  .stakedlppage
    .stackcardbox
    .buytradedata
    table
    tbody
    > tr:first-child
    > td:last-child {
    text-align: right !important;
  }

  .staketablebox .view-btn {
    margin-top: -10px;
  }

  .stakepanel .staketablebox > div img {
    display: inline-block;
  }

  .stakepanel .staketablebox .coinicon {
    display: inline-block !important;
  }

  .stakepanel .panelcontentbox {
    background: none !important;
    padding: 0px;
  }

  .stakepanel .staketablebox > div,
  .stakepanel .staketablebox {
    display: inline-block !important;
    width: 100%;
    white-space: nowrap;
  }

  .stakepanel .staketablebox {
    background: #fff;
    border-radius: 20px;
    position: relative;
    padding: 10px;
    border: 1px solid #fff;
  }

  .stakepanel .staketablebox > div {
    width: 50%;
    display: inline-block;
    border: 0px !important;
    float: left;
    padding: 10px 10px;
    font-size: 16px;
  }

  .stakepanel .staketablebox > div:first-child {
    width: 100%;
    padding-bottom: 12px;
  }

  .stakepanel .staketablebox > div:nth-child(2) br {
    display: none;
    padding-left: 20px;
  }
  .stakepanel .apyicon
  {
    margin-right:4px;
  }

  .stakeflexbox > div:last-child .staketablebox .coinicon {
    float: left;
    margin-right: 7px;
  }

  .footerbottom .accordion {
    display: inline-block;
    width: 100%;
  }

  .footerbottom .accordion-button::after {
    display: inline-block;
    filter: grayscale(1) invert(1);
  }

  .footerbottom .accordion > div {
    padding-bottom: 16px;
  }

  .footerbottom .accordion-body {
    padding: 0px 13px;
  }

  .footerbottom .accordion-collapse {
    display: none;
    transition: all 0.5s;
  }

  .footerbottom .accordion > div .accordion-collapse.show {
    display: block;
  }

  .stakeflexbox > div:last-child {
    margin-top: 5px;
  }

  .stakeflexbox .owl-stage > div:first-child {
    padding-left: 0px;
  }

  .stakeflexbox .owl-stage > div:last-child {
    padding-right: 0px;
  }

  .stakepanel .panelcontentbox {
    min-height: inherit;
    border: 0px;
  }

  .tokentable .heading-box {
    padding: 10px 10px;
  }

  .stakeicon hr {
    padding: 0px 16px;
  }

  .stackcardbox .moredetailsicon,
  .stackcardbox .moredetailsicon td {
    text-align: center !important;
    width: 100% !important;
  }

  .stakedlppage .stackcardbox .buytradedata table {
    background: #291a64;
    border-top: 0px;
    padding: 7px 6px;
  }

  .stakedlppage .stackcardbox tr {
    padding: 8px 12px;
  }

  .stakedlppage .stackcardbox table tbody .buytradedata tr.visibilityhidden,
  .visibilityhidden {
    display: none !important;
    padding: 0px !important;
  }

  .stakedlppage .stackcardbox .buytradedata tr > td:first-child {
    text-align: left !important;
  }

  .stakedlppage .stackcardbox .buytradedata table tr > td:last-child {
    text-align: right !important;
  }

  .stakedlppage .stackcardbox .stakeicon {
    display: table;
  }

  .stakedlppage .stackcardbox .listtable > tbody > tr {
    display: table !important;
    width: 100% !important;
  }

  .stakedlppage .stackcardbox table.listtable > tbody > tr > td {
    width: 50%;
    display: table-cell !important;
    padding: 7px 14px !important;
    text-align: left important;
  }

  .stakedlppage .stackcardbox .listtable > tbody > tr > td:last-child {
    text-align: right !important;
  }

  .stakedlppage .stackcardbox table tbody > tr:nth-child(odd) td,
  .stakedlppage
    .stackcardbox
    .listtable
    > tbody
    > tr:first-child
    > td:last-child {
    text-align: left !important;
  }

  .stackcardbox table tbody tr.moredetailsicon td,
  .stakedlppage .stackcardbox .table tbody tr.moredetailsicon td {
    text-align: center !important;
  }

  .stakedlppage .stackcardbox table tbody tr.buytradedata > td {
    padding: 0px !important;
  }

  .stakedlppage .stackcardbox .table tbody tr.moredetailsicon td {
    padding: 12px 10px !important;
  }

  .stakedlppage .webhide {
    display: table;
  }

  .stakedlppage .stackcardbox table tbody .mobilehide {
    display: none !important;
  }



  .view-btn {
    padding: 11px 16px;
    font-size: 14px;
  }


  .subscibe {
    float: none;
  }

  .contentbox {
    padding: 20px 20px;
  }

  .stakedtab .d-flex {
    justify-content: center;
    width: 100%;
  }

  .historytab {
    min-width: 73%;
  }
  .gridparentbox, footer, .userheader {
    padding-left: 0px;
    padding-right: 0px;
  }
  .topbanner .tabbanner
  {
    position: relative;
    top:inherit;
    right: inherit;
    margin-bottom: 16px;
  } 
  .innerpagecontent h3
  {
    text-align: center;
  }
  .userheader .navbar-toggler-icon
  {
    display: none;
  }
  .userheader .collapse 
  {
    display: block;
  }
  .userheader .collapse>ul
  {
    flex-direction: row;
    position: absolute;
    right: 7px;
    top: 15px;
  }
  .userheader .navbar-brand .logo {
    margin-left: 27px;
    margin-top: 9px;
  }
 
  .features .heading-title {
    font-size: 28px !important;
    line-height: 35px;
  }
  .wlltfrm-circlebg::after
  {
    display: none;
  }
  .homebannerbg
  {
    height: auto;
  }
  .wallet-frm .h4 {
    font-size: 24px;
  }
  .partcal-img.top svg {
    width: 72px;
  }
  .partcal-img.bottom svg {
    width: 64px;
  }
  .faqpage .accordion-item .accordion-button h4 {
    font-size: 20px;
    line-height: 30px;
  }
  .faqpage .content {
    font-size: 17px;
    line-height: 28px;
  }
  .faqpage .accordion-button {
    padding-bottom: 9px;
  }  
  .stakedlppage
  .stackcardbox
  .listtable
  > tbody
  > tr.nodata:first-child
  > td:last-child {
  text-align: center !important;
}
.responsiveTable tbody tr:nth-child(even) {
  background: #f4f7f9;
}
.responsiveTable tbody tr {
  border: 0 !important;
}
.partnerdata > div {
  width: 50%;
}

.price-banner .price-box {
  justify-content: center;
}

.price-banner .price-box .h6,
.price-banner .price-box .h5 {
  color: #fff;
}

.headbg.homeheader .navbar-nav .coinselectbox a.dropdown-bs-toggle:hover {
  color: #3f023b !important;
}

.headbg.homeheader .profiledrop p > span {
  color: #3f023b !important;
  border-color: #3d85c666;
}


.homeheader .navbar-collapse {
  border-color: #f5f4f4;
}

.headbg.homeheader .connecttable > div {
  color: #0b2143;
}

.headbg.homeheader .dropdown-menu {
  position: relative;
  width: 100%;
  left: 0 !important;
  right: inherit !important;
  max-width: 287px;
  border-radius: 6px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.headbg.homeheader .dropdown-menu a:hover,
.headbg.homeheader .dropdown-menu a:focus,
.headbg.homeheader .dropdown-menu a:active {
  background: #0b214412;
}

.headbg.homeheader .navbar-nav li a.nav-link {
  padding: 10px !important;
}
.headbg.homeheader .navbar-nav li a.nav-link.btn {
  width: 100%;
  max-width: 152px;
  text-align: center;
}

.headbg.homeheader .navbar-nav li a.nav-link span {
  color: #fff !important;
}

.home section {
  padding: 50px 0px;
}

.sitebannercontent .table-content > div {
  display: block;
  width: 100% !important;
}
.homebannerbg .h3,
.homebannerbg .h5 {
  color: #fff;
  text-align: center;
  padding: 0;
}

.sitebannercontent {
  text-align: center;
  padding-top: 11vh !important;
}

.headbg.homeheader {
  background: #fff !important;
  backdrop-filter: blur(1px);
}

.add-form {
  left: 17%;
  margin: 0 auto;
}

.how-caret {
  display: none;
}

.add-form {
  position: relative;
  left: inherit;
  background: #f5f9fc;
}

.add-form .h4 {
  color: #3f023b;
}

.home .heading-title {
  text-align: center !important;
  font-size: 30px;
}

.accordion-button {
  padding:15px 20px !important;
}

.accordion-button::after {
  right: 15px;
}

.faq-bg h4 {
  font-size: 21px;
}



.aboutbg {
    padding: 50px 0px !important;
  }
  .price-banner {
    display: inline-block !important;
    width: 100%;
  }
  .price-banner > div {
    width: 50% !important;
    float: left;
    padding-bottom: 17px;
  }
  .viewbg .row>div:first-child
  {
    width:100%;
  }
  .viewbg .row>div:last-child
  {
    width:100%;
  }
  .v-flex
  {
    display: inline-block;
    width:100%;
  }
  .v-flex>div
  {
    width:50%;
    float: left;
    padding: 0px;
  }
  .aboutboxbg
  {
    min-height: inherit;
    padding: 25px;
  }
  .aboutboxbg .content
  {
    max-width: 100%;
    text-align: center;
  }
  .faq-bg h4 {
    font-size: 17px;
    width: 84%;
    line-height: 23px;
  } 
  .v-box .h4 {
    font-size: 28px;
  }
  .mobileappbg
  {
    text-align: center;
  }
  .infoadsbg .row>div
  {
    text-align: center !important;
  }
  .infoadsbg .row>div:last-child
  {
    margin-top:14px;
  }
  .infoadsbanner
  {
    padding:15px 0px !important;
  }
  .homefooter .content
  {
    padding-right:0px;
  }
  .wallet-frm .coinicon {
    width: 26px;
  }
  .homeheader .navbar-toggler
  {
    margin-top: 11px;
    width: 30px;
    height: 30px;
  }
  .headbg.homeheader
  {
    position: fixed;
  }
  .contentpages
  {
    padding-top: 100px !important;
  }
  .leftsidemenu li .nav-link {
    padding: 2px 10px;
  }
  
}

@media (max-width: 600px) 
{
  .sitebannercontent 
  {
    padding-bottom: 4vh;
  }
  .no-record {
    max-width: 100px;
  }
  .settinglimit li {
    margin-bottom: 0px;
  }

  .convertbox {
    text-align: left;
    font-size: 14px;
  }

  .swapbalance .h4,
  .swapbalance .h5 {
    font-size: 14px;
  }

  .labelbox .pull-right > span {
    display: block;
  }

  .paneldarkbox {
    padding: 15px;
  }

  .modalbgt .modal-body,
  .modalbgt .modal-header {
    padding: 22px 20px;
    padding-top: 7px;
  }

  .labelbox .pull-right {
    float: none;
    width: 100%;
    text-align: center;
    justify-content: center;
  }

  .labelbox {
    margin-bottom: 8px !important;
  }

  .swapbox i img {
    max-width: 36px;
  }

  .cryptobg .input-group-text i img {
    max-width: 15px;
  }

  .siteformbox .form-group {
    margin-bottom: 16px;
  }

  .stackpage > div {
    width: 100%;
    max-width: 100%;
    flex: 100%;
  }

  .stackpage .panelcontentbox {
    min-height: inherit;
  }

  .stakedtab .tabrightbox {
    margin-bottom: 15px;
  }

  .stakedtab .tabrightbox > div {
    width: 100%;
    max-width: 100%;
  }

  .cardlist li a > span {
    display: none;
  }

  .cardlist ul li a {
    padding: 10px 8px;
  }

  .supportsearch > div {
    width: 100%;
  }

  .cardlist ul {
    justify-content: center;
  }

  .supportsearch .form-group {
    margin-bottom: 0px;
  }

  .tabbanner li a {
    padding: 9px 13px !important;
  }

  .suucessmap .coinicon {
    width: 35px;
  }

  .suucessmap::before {
    top: 15px;
  }

  .suucessmap .btn-success {
    width: 25px !important;
    height: 25px;
    padding-top: 2px;
  }

  .innerpagecontent p {
    font-size: 13px;
  }

  .cryptobg .pull-right {
    float: none;
    margin-bottom: 10px !important;
  }

  .wallet-frm .form-group.cryptobg .pull-right, .wallet-frm .cryptobg .input-group > span:last-child
  {
    margin-bottom: 0px !important;
  }
  .cryptobg .input-group > span {
    margin-bottom: 21px;
  }
  .wlltfrm-circlebg .siteformbox>div:nth-child(2) img
  {
    width:100%;
    max-width: 20px;
  }

  .liquiditypage .swapbox .input-group-text > div:first-child {
    width: 100%;
  }

  .liquiditypage .swapbox .input-group-text > div:last-child {
    /* margin-top: 20px; */
    text-align: center;
    padding: 0px;
  }

  .liquiditypage .cryptobg .settinglimit {
    justify-content: center;
    display: flex;
  }

  .buytokenpage .cryptobg .text-right {
    text-align: center;
  }

  .buytokenpage .convertbox {
    width: 50%;
    text-align: left;
  }

  .buytokenpage .input-group-text {
    display: flex;
  }

  .alertboxcenter h4 {
    font-size: 18px;
  }

  .walletboxicon {
    margin-bottom: 10px;
    max-width: 47px;
  }

  .liquiditypage .swapbox .pull-right,
  .buytokenpage .swapbox .pull-right {
    font-size: 12px;
  }

  .swapexchange .convertbox {
    min-width: 98px !important;
    font-size: 15px !important;
    min-height: auto;
    padding: 6px 0px;
  }

  .swapbox .coinicon {
    width: 23px;
    xtop: 0px;
  }

  .cryptobg .form-control,
  .cryptobg .input-group-text {
    font-size: 21px !important;
  }

  .buytokenpage .swapbox .form-control {
    font-size: 14px !important;
  }

  .liquiditypage .swapbox .settinglimit {
    position: absolute;
    left: 0px;
    bottom: 9px;
    justify-content: left;
    z-index: 9;
  }

  .cryptobg .settinglimit li a {
    position: relative;
    top: 0px;
  }

  .liquiditypage .swapbox .input-group {
    display: block;
  }

  .liquiditypage .swapbox .input-group-text {
    width: 100%;
    max-width: 200px;
  }

  .liquiditypage .labelbox {
    position: absolute;
    right: 9px;
    top: 26px;
    width: auto;
  }
  .userheader .navbar-nav li a.nav-link {
    padding: 6px 11px !important;
    font-size: 14px;
  }
  .userheader .collapse>ul {
    top: 21px;
  }
  .stakedtab
  {
    padding:3px;
  }
  .nodata>td
  {
    padding: 30px 0px !important;
  }
}
@media (max-width:640px)
{
  .marketpage .contentbox
  {
    padding:0px;
  }
  .marketpage table tbody tr th, .marketpage table tbody tr td {
    padding: 4px 14px !important;
  }
  .marketpage .coinicon {
    width: 26px;
    margin-right:3px;
  }
  .responsiveTable tbody tr>td.pivoted
  {
    text-align: right !important;
  }
  .responsiveTable tbody .tdBefore
  {
    text-align: left;
  }
  .h-content.d-flex
  {
    display: inline-block !important;
    width:100%;
  }
  .h-content>div
  {
    margin: 0px 0px;
    padding: 24px;
    margin-bottom: 17px;
  }
  .home .content {
    font-size: 17px;
    line-height: 27px;
    margin: 0px;
  }
  .h-box .h6 {
    font-size: 22px;
}
  .f-box>div
  {
    display: block;
    width:100% !important;
    text-align: center;
  }
  .f-box>div:first-child::before
  {
    display: none;
  } 
  .h-box img
  {
    width:100%;
    max-width: 40px;
  }
  .h-box>div:first-child {
    width: 64px;
  }
  .ficonbg { 
    margin:0px auto;
    margin-bottom: 15px;
  }
  .foot-list li
  {
    padding-bottom: 10px;
  }

}
@media (max-width:575px)
{
  .footer-gray-bg .row>div
  {
    width:100%;
    text-align: center;
  }
  .sociallist
  {
    float: none;
    margin-top: 12px;
  }
  .homefooter .footer-gray-bg .row>div
  {
    text-align: left;
  }
  .v-box .h6 {
    font-size: 17px;
  }
  .mobileappbg .heading-title
  {
    padding-bottom:10px !important;
  }
  .leftsidescroll i 
  {   
    width: 54px;
    height: 50px;  
    padding-top:12px;
  }
  .leftsidemenu li img {
    width: 24px;
  }
  .leftsidemenu {
    width: 95px;
  }
  .leftsidemenu li .nav-link:hover>div
  {
    left: 101px;
    top: 10px;
  }
}
@media (max-width: 550px) {
  .liquidtybalance {
    display: block !important;
  }

  .liquidtybalance .form-group {
    margin-bottom: 10px;
  }

  .stakepanel .panelcontentbox {
    padding: 0;
  }

  .inputsearch .input-group {
    width: 100%;
    max-width: 152px;
  }

  .stackcardbox tr {
    width: 50% !important;
  }

  .stackcardbox tr.cellbutton {
    width: 100% !important;
  }

  .cryptobg .settinglimit li a {
    padding: 5px 8px;
    font-size: 11px;
  }

  .swapmobileapage .cryptobg .pull-right {
    display: block;
    width: 100%;
    margin-bottom: 0px !important;
  }

  .swapmobileapage .cryptobg .labelinput {
    display: block;
    width: 100%;
  }

  .swapmobileapage .cryptobg .pull-right > span:first-child,
  .swapmobileapage
    .siteformbox
    > div:nth-child(3)
    .labelinput
    > span:last-child
    > span {
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .swapmobileapage .cryptobg .pull-right > span:last-child {
    position: relative;
    left: 8px;
  }

  .cryptobg .form-control {
    background: none !important;
    height: auto !important;
    padding: 0px;
  }

  .profiledrop .dropdown-menu {
    min-width: 233px;
  }

  .connecttable > div {
    font-size: 10px !important;
  }

  .paymentlogo {
    flex-wrap: wrap;
  }

  .paymentlogo li {
    width: 100%;
  }

  .innerpagecontent h3 {
    font-size: 20px;
    line-height: 26px;
  }


    .t-doller {
      top: 25px !important;
    }
    .partnerdata > div {
      width: 100%;
    }
  
    .v-flex > div {
      width: 100%;
    }
  
    .homebannerbg .h3 {
      font-size: 30px;
      line-height: 36px;
    }
    .h-content>div {
      padding: 20px;
      }
  
    .accordion-button {
      padding-bottom: 0px;
    }
  
    .home .heading-title {
      font-size: 26px;
      margin: 0;
      line-height: 31px;
    }
    .features .heading-title {
      font-size: 23px !important;
      line-height: 32px !important;
    }
    .v-box .h4 {
      font-size: 25px;
      float: right;
    }
    .wallet-frm .cryptobg .input-group > span > span {
      font-size: 16px;
  }
  .tabbanner li a {
    padding: 9px 10px !important;
    font-size: 15px !important;
}
 }

@media (max-width:500px)
{
  .userheader .collapse>ul
  {
    position: relative;
    top:inherit;
    right:0px;
  }
  .mobiletoggle
  {
    left: inherit;
    right: 10px;
  }
  .userheader .navbar-brand .logo
  {
    margin-left:0px;
  }
  .userheader .navbar-nav li
  {
    width:100%;
  }
  .userheader .profiledrop a.dropdown-bs-toggle, .userheader .coinselectbox a.dropdown-bs-toggle, .userheader .custom-menu-li-style .btn
  {
    max-width: 100%;;
  }
  .gridparentbox {
    padding-top: 134px;
  }
  .pagewrapperbox .headbg, .userheader{
    padding: 7px 11px !important;
  }
}

@media (max-width: 450px) {
  .labelbox .pull-right {
    display: block;
  }

  .labelbox .settinglimit li {
    margin-top: 10px;
    float: none;
    display: inline-block;
  }

  .navbar-brand .logo {
  }

  .leftsidemenu {
  }

  .gridparentbox {
  }

  .blancemobile > div > span {
    font-size: 14px !important;
  }

  .blancemobile > div {
    padding-bottom: 12px;
  }

  .balancetable.lightdark > div {
    padding: 8px 10px;
  }

  .coinicon {
    width: 24px;
  }

  .input-group-text .pe-3 {
    padding-right: 2px !important;
  }

  .settinglimit {
    display: inline-block;
  }

  .transheight.settinglimit li a,
  .tolerhight.settinglimit li a {
    padding: 9px 10px;
    font-size: 12px;
  }

  li.inputsearch .form-control,
  li.inputsearch .input-group-text {
    height: 35px !important;
  }

  .inputsearch .input-group {
    max-width: 100%;
    margin-top: 0px;
  }

  .modalbgt .modal-title {
    font-size: 21px;
  }

  .settingmodal .settinglimit li {
    padding: 0px 3px;
  }

  .sttingdealine > p:first-child {
    font-size: 13px;
    width: 188px;
    line-height: normal;
  }

  .swapexchange .convertbox {
    font-size: 13px;
  }

  .cryptobg {
    padding: 13px;
  }

  .tooltipnotes {
    padding: 5px 0px;
  }

  .swapexchange .convertbox {
    min-width: 71px !important;
    font-size: 17px !important;
  }

  .swapexchange .contentbox {
    padding: 13px;
  }

  .swapbox .coinicon {
    width: 17px;
  }

  .cryptobg .form-control,
  .cryptobg .input-group-text {
    font-size: 15px !important;
    height: auto;
  }
  .wallet-frm .form-group.cryptobg {
    padding: 10px 8px;
    padding-bottom: 0px;
  }
  .wallet-frm .siteformbox label {
    font-size: 16px;
  }

  .buytokenpage .swapbox .form-control {
    font-size: 18px !important;
  }

  .liquiditypage .swapbox .input-group-text {
    max-width: 134px;
  }

    .homebannerbg .h3 {
      font-size: 28px;
      line-height: 40px;
    }
    .price-banner > div {
      width: 100% !important;
    }
    .price-banner > div > div {
      width: 100%;
    }
    .price-banner > div > div:first-child {
      width: 85px;
    }
    .price-banner .price-box .h6 span {
      float: right;
    }
    .ficonbg {
      width: 60px;
      height: 60px;
      margin: 0px auto;
      margin-bottom: 19px;
    }
    .f-box .f-icon {
      max-width: 20px;
  }
  .h-box .h6 {
    font-size: 19px;
    line-height: 25px;
  }
  .h-box>div, .h-box
  {
    display: block;
    width:100% !important;
    text-align: center;
  }
  .h-box>div:first-child
  {
    margin-bottom: 15px;
  }
  .h-box img {
    max-width: 32px;
  }
  .f-box .h5 {
    font-size: 22px;
    line-height: normal;
  }
  .f-flex > div {
    padding: 9px 0px;
  }
  .sitebannercontent {
    padding-top: 15vh !important;
  }
}

@media (max-width:400px)
{
  .homebannerbg .h3 {
    font-size: 25px;
    line-height: 33px;
  }
  .v-box .h4 {
    font-size: 21px;
  }
  .features .heading-title {
    font-size: 21px !important;
    line-height: 29px !important;
  }
}

@media (max-width: 340px) {
  .leftsidemenu .mobilebottommenu {
    position: relative;
    bottom: inherit;
    padding-top: 40px;
    padding-right: 0px;
  }

  .swapexchange .convertbox {
    min-width: 57px !important;
    font-size: 16px !important;
  }

  .swapexchange .contentbox {
    padding: 13px;
  }

  .swapbox .coinicon {
    width: 17px;
    top: -1px;
  }

  .cryptobg .form-control,
  .cryptobg .input-group-text {
    font-size: 15px !important;
  }

  .buytokenpage .swapbox .form-control {
    font-size: 12px !important;
  }
  .home .heading-title {
    font-size: 21px;
    line-height: 28px;
  }
  
}

@media (max-height: 850px) {
  .homebannerbg {
    height: auto;
  }
}
